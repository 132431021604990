import { Stack, Typography } from '@mui/material';
// import { useEffect, useState } from 'react';

export function Footer() {
    // const [isMobileView, setIsMobileView] = useState<boolean>(
    //     window.innerWidth <= 600
    // );

    // useEffect(() => {
    //     const handleResize = () => {
    //         setIsMobileView(window.innerWidth <= 600);
    //     };

    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    return (
        <footer>
            <Stack
                direction="column"
                spacing={{ xs: 1, sm: 0 }}
                useFlexGap
                sx={{
                    backgroundColor: '#161205',
                    flexGrow: 1,
                    py: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'Heebo, sans-serif',
                        textAlign: 'start',
                        color: '#fff',
                    }}
                >
                    © 2023 - Now
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'Heebo, sans-serif',
                        textAlign: 'start',
                        color: '#fff',
                    }}
                >
                    Metric Sounds d.b.a. Rhythm & Romance
                </Typography>
            </Stack>
        </footer>
    );
}
