export const RoutesURL = {
    index: '/',
    notFound: '/*',
    DJs: '/djs',
    FAQ: '/faq',
    Services: '/services',
    Contact: '/contact',
    Availability: '/availability',
    Confirmation: '/confirmation',
};
