import React, { useEffect, useState } from 'react';

import {
    Box,
    Button,
    Divider,
    Paper,
    Rating,
    Stack,
    Typography,
} from '@mui/material';

import logo_s_trans from '../../assets/Logo_Small_Transparent.png';
import yellow_decks from '../../assets/YellowDecksSmall.png';
import wedding_dance_1 from '../../assets/WeddingDance1Small.png';
import wedding_dance_2 from '../../assets/WeddingDance2Small.png';
import wedding_dance_3 from '../../assets/WeddingDance3Small.png';
import SpeakerOutlinedIcon from '@mui/icons-material/SpeakerOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useNavigate } from 'react-router-dom';
import { RoutesURL } from '../../constants/routes';
import { Footer } from '../Footer';

export function Home() {
    const [isMobileView, setIsMobileView] = useState<boolean>(
        window.innerWidth <= 600
    );
    const [isTabletView, setIsTabletView] = useState<boolean>(
        window.innerWidth <= 1000
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 600);
            setIsTabletView(window.innerWidth <= 1000);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navigate = useNavigate();

    return (
        <Stack direction="column" sx={{ flexGrow: 1 }}>
            {/*Mosaic section*/}
            <Stack
                direction={isTabletView ? 'column' : 'row'}
                spacing={{ xs: 1, sm: 2 }}
                useFlexGap
                flexGrow={1}
                m={isMobileView ? 0 : 2}
                sx={{ justifyContent: 'center', mb: isMobileView ? 1 : 8 }}
            >
                {isMobileView && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={logo_s_trans}
                            alt="Rhythm & Romance Logo"
                            loading="lazy"
                            style={{
                                maxWidth: '97%',
                                height: 'auto',
                            }}
                        />
                    </Box>
                )}
                <Paper
                    elevation={3}
                    sx={{
                        flexBasis: isMobileView ? 'inherit' : '40%',
                        p: isMobileView ? 2 : 4,
                        backgroundColor: '#D9D9D9',
                        display: 'flex',
                    }}
                >
                    <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        flexGrow={1}
                    >
                        {!isMobileView && (
                            <Box>
                                <img
                                    src={logo_s_trans}
                                    alt="Rhythm & Romance Logo"
                                    loading="lazy"
                                    style={{
                                        maxWidth: '50%',
                                        height: 'auto',
                                    }}
                                />
                            </Box>
                        )}
                        <Stack
                            direction="column-reverse"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                        >
                            <Typography
                                variant="h1"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#2D2A1E',
                                    lineHeight: '1.5em',
                                    letterSpacing: '0',
                                    ml: 1,
                                    mb: 6,
                                }}
                            >
                                We offer professional DJ services to make your
                                wedding celebration truly memorable and
                                stress-free for you and your guests.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontSize: isMobileView ? '50px' : '70px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.2',
                                    letterSpacing: '-0.01562em',
                                    mx: isMobileView ? 1 : 'inherit',
                                    mt: isMobileView ? 2 : 'inherit',
                                    ml: 1,
                                    mb: 4,
                                }}
                            >
                                More Than Remarkable.
                            </Typography>
                        </Stack>
                        <Stack
                            direction={isMobileView ? 'column' : 'row'}
                            spacing={2}
                            sx={{ ml: 1 }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: '#D4AF37',
                                    ':hover': { backgroundColor: '#846b1c' },
                                }}
                                endIcon={<SpeakerOutlinedIcon />}
                                onClick={() => navigate(RoutesURL.Services)}
                            >
                                Browse Packages
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    textTransform: 'none',
                                    color: '#2D2A1E',
                                    borderColor: '#2D2A1E',
                                    ':hover': {
                                        backgroundColor: '#D4AF37',
                                        color: '#FFF',
                                        borderColor: '#D4AF37',
                                    },
                                }}
                                endIcon={<CalendarMonthOutlinedIcon />}
                                onClick={() => navigate(RoutesURL.Availability)}
                            >
                                Check Availability
                            </Button>
                        </Stack>
                        <Stack
                            direction="row"
                            sx={{
                                flexGrow: 1,
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                ml: 1,
                                mt: isTabletView ? 2 : 'inherit',
                            }}
                        >
                            <Button
                                variant="text"
                                sx={{
                                    textTransform: 'none',
                                    color: '#2D2A1E',
                                    ':hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    },
                                }}
                                endIcon={<LocalPhoneRoundedIcon />}
                                onClick={() => navigate(RoutesURL.Contact)}
                            >
                                Contact Us
                            </Button>
                        </Stack>
                    </Stack>
                </Paper>
                <Stack
                    direction="column"
                    spacing={{ xs: 1, sm: 2 }}
                    useFlexGap
                    sx={{ flexBasis: '40%' }}
                >
                    <Stack
                        direction="row"
                        spacing={{ xs: 1, sm: 2 }}
                        useFlexGap
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundImage: `url(${wedding_dance_1})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                flexGrow: 1,
                            }}
                        />
                        <Paper
                            elevation={3}
                            sx={{
                                flexBasis: '45%',
                                backgroundColor: '#D4AF37',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                p: 2,
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: '30px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#FFF',
                                    mb: 2,
                                }}
                            >
                                Why Us?
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#FFF',
                                    mb: 2,
                                    textAlign: 'center',
                                    lineHeight: '18px',
                                }}
                            >
                                We have over 10 years of experience in
                                entertainment and have made 500+ couples happy
                                on their special day.
                            </Typography>
                        </Paper>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={{ xs: 1, sm: 2 }}
                        useFlexGap
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                flexBasis: '45%',
                                backgroundColor: '#D4AF37',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                p: 2,
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    textAlign: 'center',
                                    fontSize: '30px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#FFF',
                                    mb: 2,
                                }}
                            >
                                Our Mission
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#FFF',
                                    mb: 2,
                                    textAlign: 'center',
                                    lineHeight: '18px',
                                }}
                            >
                                We strive to give a personalized and spectacular
                                experience to each couple. We proactively ensure
                                that your needs are met and that your reception
                                is a smashing success.
                            </Typography>
                        </Paper>
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundImage: `url(${wedding_dance_2})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                flexGrow: 1,
                            }}
                        />
                    </Stack>
                    <Paper
                        elevation={3}
                        sx={{
                            backgroundImage: `url(${yellow_decks})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            flexGrow: 1,
                            minHeight: '150px',
                            width: 'fill-available',
                        }}
                    />
                </Stack>
            </Stack>
            {/*The difference?*/}
            <Stack
                direction={isTabletView ? 'column-reverse' : 'row'}
                spacing={{ xs: 1, sm: 8 }}
                useFlexGap
                sx={{
                    backgroundColor: '#D4AF37',
                    flexGrow: 1,
                    justifyContent: 'center',
                    py: isMobileView ? 6 : isTabletView ? 8 : 12,
                    px: isMobileView ? 4 : isTabletView ? 8 : 16,
                }}
            >
                {!isTabletView && (
                    <Paper
                        elevation={8}
                        sx={{
                            backgroundImage: `url(${wedding_dance_3})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            flexBasis: '45%',
                            borderRadius: '15px',
                        }}
                    />
                )}
                <Stack
                    direction="column"
                    sx={{
                        flexBasis: isTabletView ? 'inherit' : '45%',
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '48px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#FFF',
                            lineHeight: '1.2',
                            mb: 4,
                        }}
                    >
                        What makes us different?
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#FFF',
                            lineHeight: '1.5',
                        }}
                    >
                        We find that we stand out in 3 key areas: equipment,
                        experience, and communication. We offer competitive
                        pricing while exceeding the industry standard when it
                        comes to gear and service. Because of our partnerships
                        with touring audio companies, we offer best in class
                        lighting and audio for all occasions; and we mean that.
                        Whether it is a small gathering of friends or a 2000+
                        guest blockbuster, we have you covered.
                    </Typography>
                    {isTabletView && (
                        <Paper
                            elevation={8}
                            sx={{
                                backgroundImage: `url(${wedding_dance_3})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                borderRadius: '15px',
                                height: isMobileView ? '220px' : '350px',
                                my: isMobileView ? 5 : 3,
                            }}
                        />
                    )}
                    <Button
                        variant="text"
                        sx={{
                            my: isTabletView ? 'inherit' : 4,
                            color: '#fff',
                            textTransform: 'none',
                            fontSize: '20px',
                            backgroundColor: '#BE9D31',
                            ':hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                            },
                        }}
                        onClick={() => navigate(RoutesURL.FAQ)}
                    >
                        Still not convinced? Visit our FAQ to learn more.
                    </Button>
                </Stack>
            </Stack>
            {/*What we offer*/}
            <Stack
                direction="column"
                spacing={{ xs: 1, sm: 5 }}
                useFlexGap
                sx={{
                    backgroundColor: '#FCF9EF',
                    flexGrow: 1,
                    py: isMobileView ? 4 : 6,
                    justifyContent: 'center',
                }}
            >
                <Stack
                    direction={isMobileView ? 'column' : 'row'}
                    spacing={{ xs: 1, sm: 8 }}
                    useFlexGap
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'center',
                        mb: isMobileView ? 2 : 'inherit',
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            flexBasis: '40%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: '48px',
                                fontWeight: 600,
                                fontFamily: 'Heebo, sans-serif',
                                lineHeight: '1.2',
                                maxWidth: isMobileView ? 'inherit' : '90%',
                                textAlign: isMobileView ? 'center' : 'end',
                                color: '#161205',
                                mx: isMobileView ? 2 : 'inherit',
                            }}
                        >
                            We Are More Than Just DJs
                        </Typography>
                    </Box>
                    <Stack
                        direction="column"
                        spacing={{ xs: 1, sm: 2 }}
                        useFlexGap
                        sx={{
                            justifyContent: isMobileView
                                ? 'center'
                                : 'flex-start',
                            flexBasis: isMobileView ? 'inherit' : '55%',
                            fontSize: '16px',
                            color: '#2D2A1E',
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '16px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                lineHeight: '1.2',
                                maxWidth: isMobileView ? 'inherit' : '90%',
                                textAlign: isMobileView ? 'center' : 'inherit',
                                mx: isMobileView ? 2 : 'inherit',
                                mt: 1,
                                mb: isMobileView ? 1 : 'inherit',
                            }}
                        >
                            We are entertainers, emcees... and audio-engineers!
                            If your party calls for live music, we can work with
                            your band to ensure that the sound is perfect. We
                            can also provide a sound system for your ceremony,
                            and a microphone for your vows. We are here to make
                            your day as stress-free as possible.
                        </Typography>
                        <Button
                            variant="contained"
                            endIcon={<ArrowForwardRoundedIcon />}
                            sx={{
                                textTransform: 'none',
                                backgroundColor: '#D4AF37',
                                ':hover': {
                                    backgroundColor: '#846b1c',
                                },
                                fontSize: '16px',
                                alignSelf: isMobileView
                                    ? 'center'
                                    : 'flex-start',
                            }}
                            onClick={() => navigate(RoutesURL.FAQ)}
                        >
                            Learn More
                        </Button>
                    </Stack>
                </Stack>
                <Divider variant="middle" />
                <Stack
                    direction={isTabletView ? 'column' : 'row'}
                    spacing={{ xs: 4, sm: 6 }}
                    useFlexGap
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'center',
                        mx: isMobileView ? 2 : 6,
                        mt: isMobileView ? 2 : 'inherit',
                        mb: isMobileView ? 3 : 'inherit',
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            flexBasis: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={{ xs: 1, sm: 2 }}
                            useFlexGap
                            sx={{ flexGrow: 1 }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: '38px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.2',
                                    mt: 1,
                                }}
                            >
                                Years of Experience
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '24px',
                                    mt: 1,
                                }}
                            >
                                Our DJs have 10+ years of experience in the
                                entertainment industry, so you can trust us to
                                make your reception a hit.
                            </Typography>
                            <Stack
                                direction="row"
                                sx={{
                                    flexGrow: 1,
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Button
                                    variant="text"
                                    endIcon={<ArrowForwardRoundedIcon />}
                                    sx={{
                                        textTransform: 'none',
                                        color: '#D4AF37',
                                        ':hover': {
                                            backgroundColor:
                                                'rgba(83, 69, 22, 0.1)',
                                        },
                                        fontSize: '16px',
                                    }}
                                    onClick={() => navigate(RoutesURL.DJs)}
                                >
                                    Our DJs
                                </Button>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            flexBasis: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={{ xs: 1, sm: 2 }}
                            useFlexGap
                            sx={{ flexGrow: 1 }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: '38px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.2',
                                    mt: 1,
                                }}
                            >
                                Massive Music Library
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '24px',
                                    mt: 1,
                                }}
                            >
                                We have access to a library with over 100,000
                                songs, so your DJ can play all your must-have
                                tunes.
                            </Typography>
                            <Stack
                                direction="row"
                                sx={{
                                    flexGrow: 1,
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Button
                                    variant="text"
                                    endIcon={<ArrowForwardRoundedIcon />}
                                    sx={{
                                        textTransform: 'none',
                                        color: '#D4AF37',
                                        ':hover': {
                                            backgroundColor:
                                                'rgba(83, 69, 22, 0.1)',
                                        },
                                        fontSize: '16px',
                                    }}
                                    onClick={() =>
                                        navigate(
                                            RoutesURL.FAQ +
                                                '?question=SeeMusicQuestion'
                                        )
                                    }
                                >
                                    Song Samples
                                </Button>
                            </Stack>
                        </Stack>
                    </Paper>
                </Stack>
                <Stack
                    direction={isTabletView ? 'column' : 'row'}
                    spacing={{ xs: 4, sm: 6 }}
                    useFlexGap
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'center',
                        mx: isMobileView ? 2 : 6,
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            flexBasis: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={{ xs: 1, sm: 2 }}
                            useFlexGap
                            sx={{ flexGrow: 1 }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: '38px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.2',
                                    mt: 1,
                                }}
                            >
                                Modern Equipment
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '24px',
                                    mt: 1,
                                }}
                            >
                                Our DJs use state-of-the-art sound and lighting
                                gear to transform any venue into a vibrant dance
                                floor.
                            </Typography>
                            <Stack
                                direction="row"
                                sx={{
                                    flexGrow: 1,
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Button
                                    variant="text"
                                    endIcon={<ArrowForwardRoundedIcon />}
                                    sx={{
                                        textTransform: 'none',
                                        color: '#D4AF37',
                                        ':hover': {
                                            backgroundColor:
                                                'rgba(83, 69, 22, 0.1)',
                                        },
                                        fontSize: '16px',
                                    }}
                                    onClick={() =>
                                        navigate(
                                            RoutesURL.FAQ +
                                                '?question=SeeGearQuestion'
                                        )
                                    }
                                >
                                    See Gear
                                </Button>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            flexBasis: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={{ xs: 1, sm: 2 }}
                            useFlexGap
                            sx={{ flexGrow: 1 }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: '38px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.2',
                                    mt: 1,
                                }}
                            >
                                Personalized Service
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '24px',
                                    mt: 1,
                                }}
                            >
                                We'll meet with you beforehand to understand
                                your taste and build a custom music playlist for
                                your big day.
                            </Typography>
                            <Stack
                                direction="row"
                                sx={{
                                    flexGrow: 1,
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Button
                                    variant="text"
                                    endIcon={<LocalPhoneRoundedIcon />}
                                    sx={{
                                        textTransform: 'none',
                                        color: '#D4AF37',
                                        ':hover': {
                                            backgroundColor:
                                                'rgba(83, 69, 22, 0.1)',
                                        },
                                        fontSize: '16px',
                                    }}
                                    onClick={() => navigate(RoutesURL.Contact)}
                                >
                                    Contact Us
                                </Button>
                            </Stack>
                        </Stack>
                    </Paper>
                </Stack>
            </Stack>
            {/*Reviews*/}
            <Stack
                direction="column"
                spacing={{ xs: 6, sm: 5 }}
                useFlexGap
                sx={{
                    backgroundColor: '#FCF9EF',
                    backgroundImage:
                        'linear-gradient(#FCF9EF 0%, #D4AF37 100%)',
                    flexGrow: 1,
                    py: isMobileView ? 4 : 12,
                    justifyContent: 'center',
                }}
            >
                <Box>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '48px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            lineHeight: '1.2',
                            textAlign: 'center',
                            color: '#161205',
                        }}
                    >
                        Hear from happy couples
                    </Typography>
                </Box>
                <Stack
                    direction={isTabletView ? 'column' : 'row'}
                    spacing={{ xs: 4, sm: 5 }}
                    useFlexGap
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'center',
                        px: isMobileView ? 4 : isTabletView ? 12 : 'inherit',
                    }}
                >
                    <Paper
                        elevation={8}
                        sx={{
                            border: 1,
                            borderColor: '#D4AF37',
                            backgroundColor: '#FFF',
                            p: 2,
                            py: 3,
                            maxWidth: isTabletView ? '100%' : '30%',
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={{ xs: 1, sm: 2 }}
                            useFlexGap
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.2',
                                    textAlign: 'start',
                                }}
                            >
                                Michelle D.
                            </Typography>
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{ alignItems: 'center' }}
                            >
                                <Rating name="read-only" value={5} readOnly />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        fontFamily: 'Heebo, sans-serif',
                                        textAlign: 'start',
                                        color: '#2D2A1E',
                                    }}
                                >
                                    Jan 2023
                                </Typography>
                            </Stack>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontSize: '24px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.5',
                                    textAlign: 'start',
                                }}
                            >
                                Great with multiple generes
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.5',
                                    textAlign: 'start',
                                }}
                            >
                                DJ Neil was incredible - he played our special
                                requests including Assyrian music and the night
                                went perfectly. It was the best night of my life
                                and the guys were a big part of making that
                                happen!
                            </Typography>
                        </Stack>
                    </Paper>
                    <Paper
                        elevation={8}
                        sx={{
                            border: 1,
                            borderColor: '#D4AF37',
                            backgroundColor: '#FFF',
                            p: 2,
                            py: 3,
                            maxWidth: isTabletView ? '100%' : '30%',
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={{ xs: 1, sm: 2 }}
                            useFlexGap
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.2',
                                    textAlign: 'start',
                                }}
                            >
                                Faith B.
                            </Typography>
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{ alignItems: 'center' }}
                            >
                                <Rating name="read-only" value={5} readOnly />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        fontFamily: 'Heebo, sans-serif',
                                        textAlign: 'start',
                                        color: '#2D2A1E',
                                    }}
                                >
                                    Oct 2023
                                </Typography>
                            </Stack>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontSize: '24px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.5',
                                    textAlign: 'start',
                                }}
                            >
                                Professional and organized
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.5',
                                    textAlign: 'start',
                                }}
                            >
                                As an experienced DJ/MC, Pat was also able to
                                adapt to things we hadn’t planned for and
                                foresee ways to keep the night on track. People
                                said that our wedding was the most they've ever
                                danced at a reception, and that they’ve never
                                been to a wedding where so many guests danced!
                            </Typography>
                        </Stack>
                    </Paper>
                    <Paper
                        elevation={8}
                        sx={{
                            border: 1,
                            borderColor: '#D4AF37',
                            backgroundColor: '#FFF',
                            p: 2,
                            py: 3,
                            maxWidth: isTabletView ? '100%' : '30%',
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={{ xs: 1, sm: 2 }}
                            useFlexGap
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.2',
                                    textAlign: 'start',
                                }}
                            >
                                Ignacio F.
                            </Typography>
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{ alignItems: 'center' }}
                            >
                                <Rating name="read-only" value={5} readOnly />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        fontFamily: 'Heebo, sans-serif',
                                        textAlign: 'start',
                                        color: '#2D2A1E',
                                    }}
                                >
                                    Feb 2024
                                </Typography>
                            </Stack>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontSize: '24px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.5',
                                    textAlign: 'start',
                                }}
                            >
                                Do not hesitate to give Pat a call
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    lineHeight: '1.5',
                                    textAlign: 'start',
                                }}
                            >
                                DJ Pat put on an electric show for my wedding. I
                                cannot say enough about how amazing his
                                performance was and how above and beyond he went
                                to make this a great night.
                            </Typography>
                        </Stack>
                    </Paper>
                </Stack>
            </Stack>
            {/*Footer*/}
            <Footer />
        </Stack>
    );
}
