import { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { Nav } from '../Nav';
import { Footer } from '../Footer';

const steps = ['Select Event Date', 'Select A Package', 'Confirm Appointment'];

export const Confirmation = () => {
    const [isMobileView, setIsMobileView] = useState<boolean>(
        window.innerWidth <= 600
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Stack
            direction="column"
            sx={{ flexGrow: 1, height: 'fill-available' }}
        >
            <Nav />
            <Stack sx={{ backgroundColor: '#FCF9EF' }}>
                <Paper
                    elevation={3}
                    sx={{
                        backgroundColor: '#FFF',
                        width: isMobileView ? '100%' : 'inherit',
                        mx: isMobileView ? 'inherit' : 'auto',
                        my: isMobileView ? 'inherit' : 6,
                        mb: 6,
                        pt: 4,
                        pb: 2,
                        px: isMobileView ? 'inherit' : 6,
                    }}
                >
                    <Stepper activeStep={3} alternativeLabel>
                        {steps.map((label) => (
                            <Step
                                key={label}
                                sx={{
                                    '& .MuiSvgIcon-root.Mui-completed': {
                                        color: '#D4AF37',
                                    },
                                    '& .MuiSvgIcon-root.Mui-active': {
                                        color: '#D4AF37',
                                    },
                                }}
                            >
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Paper>
                <Stack>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: isMobileView ? '42px' : '48px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            lineHeight: '1.2',
                            maxWidth: isMobileView ? 'inherit' : '90%',
                            textAlign: 'center',
                            color: '#161205',
                            mx: isMobileView ? 2 : 'auto',
                        }}
                    >
                        Appointment set!
                    </Typography>
                    <Stack gap={2} sx={{ my: 4 }}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: isMobileView ? '16px' : '18px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                lineHeight: '1.2',
                                color: '#2D2A1E',
                                maxWidth: isMobileView ? 'inherit' : '70%',
                                textAlign: 'center',
                                mx: isMobileView ? 2 : 'auto',
                            }}
                        >
                            We're almost there! One of our team members will
                            reach out to you shortly. We typically call within
                            24 hours. If you have any questions, feel free to
                            reach out to us at 224-612-0738 or
                            info@RhythmAndRomanceDJs.com.
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: isMobileView ? '16px' : '18px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                lineHeight: '1.2',
                                color: '#2D2A1E',
                                maxWidth: isMobileView ? 'inherit' : '100%',
                                textAlign: 'center',
                                mx: isMobileView ? 2 : 'auto',
                            }}
                        >
                            We are excited to be a part of your special day!
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Box
                sx={{ backgroundColor: '#FCF9EF', height: 'fill-available' }}
            />
            <Footer />
        </Stack>
    );
};
