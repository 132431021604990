import React, { useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { AppRouter } from './AppRouter';
import { BrowserRouter } from 'react-router-dom';
import Theme from './styling/Theme';

declare module '@mui/material/TextField' {
    interface TextFieldPropsColorOverrides {
        white: true;
    }
}

// Outermost shell of core application post-authentication
function App() {
    useEffect(() => {
        // Change Tab Title
        document.title = 'Rhythm & Romance DJs';
    }, []);

    // Renders the Layout (1st Layer) -> Admin (2nd Layer)
    return (
        <Theme>
            <React.Fragment>
                <CssBaseline />
                <BrowserRouter>
                    <AppRouter />
                </BrowserRouter>
            </React.Fragment>
        </Theme>
    );
}

// Exports for use by `./index.tsx`
export default App;
