import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Nav } from '../Nav';

import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import Headshot1 from '../../assets/Headshot1.png';
import Headshot2 from '../../assets/Headshot2.png';
import Headshot3 from '../../assets/Headshot3.png';
import Headshot4 from '../../assets/Headshot4.png';
import { Footer } from '../Footer';
import { useNavigate } from 'react-router-dom';
import { RoutesURL } from '../../constants/routes';

export function DJs() {
    const [isMobileView, setIsMobileView] = useState<boolean>(
        window.innerWidth <= 600
    );
    const [isTabletView, setIsTabletView] = useState<boolean>(
        window.innerWidth <= 1000
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 600);
            setIsTabletView(window.innerWidth <= 1000);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navigate = useNavigate();

    return (
        <Stack direction="column" sx={{ flexGrow: 1 }}>
            <Nav />
            <Stack
                direction={isTabletView ? 'column' : 'row'}
                alignItems="center"
                justifyContent="center"
                flexGrow={1}
                gap={isMobileView ? 2 : 6}
                sx={{
                    backgroundColor: '#FCF9EF',
                    py: 5,
                    px: isTabletView ? 3 : 10,
                }}
            >
                {isTabletView && (
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: isMobileView ? '42px' : '48px',
                            lineHeight: isMobileView ? '56px' : '64px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#161205',
                            textAlign: 'center',
                            mb: 3,
                        }}
                    >
                        Meet our expert wedding DJs
                    </Typography>
                )}
                <Box
                    sx={{
                        flexBasis: '30%',
                        my: isTabletView ? -3 : 0,
                    }}
                >
                    <img
                        src={Headshot1}
                        alt="MC Patrick Headshot"
                        loading="lazy"
                        style={{
                            width: '100%',
                            height: '100%',
                            maxWidth: '280px',
                            objectFit: 'cover',
                        }}
                    />
                </Box>
                <Stack
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    flexGrow={1}
                    gap={2}
                    sx={{
                        flexBasis: '60%',
                        mt: isTabletView ? 1 : 0,
                        mx: isMobileView ? 0 : isTabletView ? 6 : 0,
                    }}
                >
                    {!isTabletView && (
                        <Typography
                            variant="h1"
                            sx={{
                                fontSize: isMobileView ? '42px' : '48px',
                                lineHeight: isMobileView ? '56px' : '64px',
                                fontWeight: 600,
                                fontFamily: 'Heebo, sans-serif',
                                color: '#161205',
                                textAlign: 'center',
                            }}
                        >
                            Meet our expert wedding DJs
                        </Typography>
                    )}
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            fontFamily: 'Heebo, sans-serif',
                            color: '#161205',
                        }}
                    >
                        Rhythm & Romance has over 10 years of experience
                        providing exceptional DJ and emcee services for
                        weddings. Our professional DJs will make your reception
                        truly memorable with perfectly-timed announcements, fun
                        dancing music, and expert coordination with all your
                        other vendors.
                    </Typography>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            fontFamily: 'Heebo, sans-serif',
                            color: '#161205',
                        }}
                    >
                        We meet with every couple prior to the big day to
                        discuss music tastes, timeline, special dances, and any
                        unique requests to ensure we create a custom reception
                        that reflects your style as a couple.
                    </Typography>
                    <Stack
                        direction="row"
                        sx={{
                            flexGrow: 1,
                            alignItems: 'flex-start',
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: 'none',
                                color: '#2D2A1E',
                                borderColor: '#2D2A1E',
                                ':hover': {
                                    backgroundColor: '#D4AF37',
                                    color: '#FFF',
                                    borderColor: '#D4AF37',
                                },
                            }}
                            endIcon={<LocalPhoneRoundedIcon />}
                            onClick={() => navigate(RoutesURL.Contact)}
                        >
                            Contact Us
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                flexGrow={1}
                gap={3}
                sx={{
                    backgroundColor: '#D4AF37',
                    py: isMobileView ? 3 : 10,
                    px: isMobileView ? 3 : 10,
                }}
            >
                <Stack
                    direction="row-reverse"
                    alignItems="center"
                    justifyContent="center"
                    flexGrow={1}
                    gap={6}
                >
                    {!isTabletView && (
                        <Box
                            sx={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                            }}
                        >
                            <img
                                src={Headshot2}
                                alt="DJ Patrick Headshot"
                                loading="lazy"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    maxWidth: '280px',
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>
                    )}
                    <Paper
                        elevation={8}
                        sx={{
                            p: 4,
                            pt: isTabletView ? 2 : 4,
                            flexBasis: isTabletView ? 'inherit' : '60%',
                            backgroundColor: '#FCF9EF',
                        }}
                    >
                        <Stack
                            direction="column"
                            alignItems={isTabletView ? 'center' : 'flex-start'}
                            justifyContent="center"
                            flexGrow={1}
                            gap={2}
                            sx={{
                                mt: 1,
                            }}
                        >
                            <Divider flexItem>
                                <Typography
                                    sx={{
                                        fontSize: isMobileView
                                            ? '40px'
                                            : '36px',
                                        lineHeight: isMobileView
                                            ? '60px'
                                            : '54px',
                                        fontWeight: 600,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#161205',
                                    }}
                                >
                                    DJ Pat
                                </Typography>
                            </Divider>
                            {isTabletView && (
                                <Box sx={{ mb: -8, mt: -4 }}>
                                    <img
                                        src={Headshot2}
                                        alt="DJ Patrick Headshot"
                                        loading="lazy"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            maxWidth: '280px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>
                            )}
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                DJ Pat is the ultimate party maestro, with an
                                unparalleled knack for turning any event into an
                                unforgettable celebration. Renowned for his
                                expertise in lighting design for industry giants
                                like Shawn Mendes, Eminem, and Kevin Bacon, Pat
                                ensures that every performance is a meticulously
                                orchestrated explosion of sound and color.
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                As a fixture aboard the Chicago Odyssey Cruise
                                Lines and local resident nostalgia DJ spinning
                                Emo anthems, Pat has cemented his status as a
                                purveyor of timeless musical experiences.
                                Additionally, as the visionary founder of Metric
                                Sounds' talent group, he has cultivated a
                                diverse background that equips him to handle any
                                challenge with finesse.
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                With a passion for Nostalgia hits from the 2000s
                                and 2010s alongside a penchant for House {'('}
                                EDM{')'}, Pat's musical repertoire knows no
                                bounds. From Latin rhythms to modern Billboard
                                chart-toppers and even crowd-pleasing country
                                classics, he possesses a deep arsenal of tracks
                                guaranteed to keep any audience on their feet
                                all night long.
                            </Typography>
                        </Stack>
                    </Paper>
                </Stack>
                <Divider
                    variant="middle"
                    flexItem
                    sx={{
                        borderBottomWidth: '3px',
                        borderRadius: '2px 2px 2px 2px',
                    }}
                />
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    flexGrow={1}
                    gap={6}
                >
                    {!isTabletView && (
                        <Box
                            sx={{
                                flexBasis: '30%',
                                justifyContent: 'flex-start',
                                display: 'flex',
                            }}
                        >
                            <img
                                src={Headshot3}
                                alt="DJ Neil Headshot"
                                loading="lazy"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    maxWidth: '280px',
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>
                    )}
                    <Paper
                        elevation={8}
                        sx={{
                            p: 4,
                            pt: isTabletView ? 2 : 4,
                            flexBasis: isTabletView ? 'inherit' : '60%',
                            backgroundColor: '#FCF9EF',
                        }}
                    >
                        <Stack
                            direction="column"
                            alignItems={isTabletView ? 'center' : 'flex-end'}
                            justifyContent="center"
                            flexGrow={1}
                            gap={2}
                            sx={{
                                mt: 1,
                            }}
                        >
                            <Divider flexItem>
                                <Typography
                                    sx={{
                                        fontSize: isMobileView
                                            ? '40px'
                                            : '36px',
                                        lineHeight: isMobileView
                                            ? '60px'
                                            : '54px',
                                        fontWeight: 600,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#161205',
                                    }}
                                >
                                    DJ Neil
                                </Typography>
                            </Divider>
                            {isTabletView && (
                                <Box sx={{ mb: -2, mt: -1 }}>
                                    <img
                                        src={Headshot3}
                                        alt="DJ Neil Headshot"
                                        loading="lazy"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            maxWidth: '280px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>
                            )}
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                DJ Neil has over a decade of entertainment
                                experience including DJ appearances at the MGM
                                Grand in Las Vegas, Chicago River cruises, and
                                various marquee wedding and nightlife venues.
                                His corporate and startup background allows him
                                to work well with clients of all backgrounds to
                                help elevate their events with carefully curated
                                setlists.
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                He specializes in corporate events, charity
                                outings, weddings, and more. As a proficient
                                public speaker, Neil can help guide your event
                                from start to finish with both articulate spoken
                                words as an MC as well as an experienced DJ to
                                keep the dancefloor going all night long.
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                His favorite genres of music are House {'('}EDM
                                {')'} and Hip Hop, although he has a deep
                                collection of classic rock and other favorite
                                tunes from throughout the decades. DJ Neil is an
                                entertainer you can count on to make your event
                                a success!
                            </Typography>
                        </Stack>
                    </Paper>
                </Stack>
                <Divider
                    variant="middle"
                    flexItem
                    sx={{
                        borderBottomWidth: '3px',
                        borderRadius: '2px 2px 2px 2px',
                    }}
                />
                <Stack
                    direction="row-reverse"
                    alignItems="center"
                    justifyContent="center"
                    flexGrow={1}
                    gap={6}
                >
                    {!isTabletView && (
                        <Box
                            sx={{
                                flexBasis: '30%',
                                justifyContent: 'flex-end',
                                display: 'flex',
                            }}
                        >
                            <img
                                src={Headshot4}
                                alt="DJ Ric Headshot"
                                loading="lazy"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    maxWidth: '280px',
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>
                    )}

                    <Paper
                        elevation={8}
                        sx={{
                            p: 4,
                            pt: isTabletView ? 2 : 4,
                            flexBasis: isTabletView ? 'inherit' : '60%',
                            backgroundColor: '#FCF9EF',
                        }}
                    >
                        <Stack
                            direction="column"
                            alignItems={isTabletView ? 'center' : 'flex-start'}
                            justifyContent="center"
                            flexGrow={1}
                            gap={2}
                            sx={{
                                mt: 1,
                            }}
                        >
                            <Divider flexItem>
                                <Typography
                                    sx={{
                                        fontSize: isMobileView
                                            ? '40px'
                                            : '36px',
                                        lineHeight: isMobileView
                                            ? '60px'
                                            : '54px',
                                        fontWeight: 600,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#161205',
                                    }}
                                >
                                    DJ Ric
                                </Typography>
                            </Divider>
                            {isTabletView && (
                                <Box sx={{ mb: 0, mt: -1 }}>
                                    <img
                                        src={Headshot4}
                                        alt="DJ Ric Headshot"
                                        loading="lazy"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            maxWidth: '280px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>
                            )}
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                DJ Ric, a luminary in the realm of Latin-infused
                                beats, draws inspiration from the pulsating
                                rhythms of tropical sounds, the infectious
                                energy of house music, and the raw authenticity
                                of hip-hop. As a resident DJ at Drink Nightclub
                                in Schaumburg, Ric commands the decks with an
                                electrifying blend of genres that captivates
                                audiences night after night. His seamless
                                transitions and intuitive understanding of crowd
                                dynamics make him a force to be reckoned with,
                                transforming the dance floor into a euphoric
                                sanctuary where music transcends boundaries.
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                Beyond the confines of the nightclub, DJ Ric
                                sets sail on the majestic waters of Chicago as a
                                featured DJ aboard the city's major cruise
                                ships. Against the backdrop of the city skyline,
                                he orchestrates sonic journeys that transport
                                passengers to realms of pure ecstasy. With each
                                beat, Elixir crafts an immersive experience that
                                celebrates the diversity of sound, leaving an
                                indelible impression on all who embark on his
                                musical odyssey.
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                Looking ahead, DJ Ric is poised to chart new
                                territories in the world of music. With a
                                relentless passion for innovation and a
                                commitment to pushing the boundaries of his
                                craft, he envisions collaborations with fellow
                                artists and producers that will redefine the
                                landscape of Latin-infused music. From remixing
                                tracks that resonate with global audiences to
                                curating immersive live experiences that
                                transcend cultural divides, DJ Ric is on a
                                mission to spread joy and unity through the
                                universal language of music.
                            </Typography>
                        </Stack>
                    </Paper>
                </Stack>
            </Stack>
            <Footer />
        </Stack>
    );
}
