import {
    Box,
    Collapse,
    Divider,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import logo_s_trans from '../../assets/Logo_Small_Transparent.png';
import { RoutesURL } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';

import { Squash as Hamburger } from 'hamburger-react';

export function Nav() {
    const [isMobileView, setIsMobileView] = useState<boolean>(
        window.innerWidth <= 600
    );
    const [isTabletView, setIsTabletView] = useState<boolean>(
        window.innerWidth <= 1000
    );

    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 600);
            setIsTabletView(window.innerWidth <= 1000);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const currentPath = window.location.pathname.toLowerCase();

    const navigate = useNavigate();

    return (
        <nav>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent={isTabletView ? 'space-between' : 'flex-start'}
                flexGrow={1}
                sx={{
                    backgroundColor: '#D4AF37',
                    px: isTabletView ? 1 : 10,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mr: isTabletView ? 'inherit' : 4,
                    }}
                    onClick={() => navigate(RoutesURL.index)}
                >
                    <img
                        src={logo_s_trans}
                        alt="Rhythm & Romance Logo"
                        loading="lazy"
                        style={{
                            width: isMobileView ? '100%' : 'auto',
                            height: isMobileView ? 'auto' : '70px',
                        }}
                    />
                </Box>
                {isTabletView ? (
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        <Hamburger rounded toggled={open} size={20} />
                    </IconButton>
                ) : (
                    <>
                        <Box
                            sx={{
                                py: 4,
                                px: 2,
                                color:
                                    currentPath === RoutesURL.index
                                        ? '#FFF'
                                        : '#000',
                                backgroundColor:
                                    currentPath === RoutesURL.index
                                        ? '#846b1c'
                                        : 'transparent',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(RoutesURL.index)}
                            >
                                Home
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                py: 4,
                                px: 2,
                                color:
                                    currentPath === RoutesURL.Services
                                        ? '#FFF'
                                        : '#000',
                                backgroundColor:
                                    currentPath === RoutesURL.Services
                                        ? '#846b1c'
                                        : 'transparent',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(RoutesURL.Services)}
                            >
                                Services
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                py: 4,
                                px: 2,
                                color:
                                    currentPath === RoutesURL.DJs
                                        ? '#FFF'
                                        : '#000',
                                backgroundColor:
                                    currentPath === RoutesURL.DJs
                                        ? '#846b1c'
                                        : 'transparent',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(RoutesURL.DJs)}
                            >
                                DJs
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                py: 4,
                                px: 2,
                                color:
                                    currentPath === RoutesURL.Contact
                                        ? '#FFF'
                                        : '#000',
                                backgroundColor:
                                    currentPath === RoutesURL.Contact
                                        ? '#846b1c'
                                        : 'transparent',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(RoutesURL.Contact)}
                            >
                                Contact
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                py: 4,
                                px: 2,
                                color:
                                    currentPath === RoutesURL.FAQ
                                        ? '#FFF'
                                        : '#000',
                                backgroundColor:
                                    currentPath === RoutesURL.FAQ
                                        ? '#846b1c'
                                        : 'transparent',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(RoutesURL.FAQ)}
                            >
                                FAQ
                            </Typography>
                        </Box>
                    </>
                )}
            </Stack>
            {isTabletView && (
                <Collapse
                    in={open}
                    sx={{
                        backgroundColor: '#D4AF37',
                        color: '#FFF',
                    }}
                >
                    <Stack
                        direction="column"
                        sx={{ alignItems: 'center', pb: 1 }}
                    >
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                cursor: 'pointer',
                                px: 4,
                                py: 1,
                            }}
                            onClick={() => navigate(RoutesURL.index)}
                        >
                            Home
                        </Typography>
                        <Divider flexItem variant="middle" />
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                cursor: 'pointer',
                                px: 4,
                                py: 1,
                            }}
                            onClick={() => navigate(RoutesURL.Services)}
                        >
                            Services
                        </Typography>
                        <Divider flexItem variant="middle" />
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                cursor: 'pointer',
                                px: 4,
                                py: 1,
                            }}
                            onClick={() => navigate(RoutesURL.DJs)}
                        >
                            DJs
                        </Typography>
                        <Divider flexItem variant="middle" />
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                cursor: 'pointer',
                                px: 4,
                                py: 1,
                            }}
                            onClick={() => navigate(RoutesURL.Contact)}
                        >
                            Contact
                        </Typography>
                        <Divider flexItem variant="middle" />
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                cursor: 'pointer',
                                px: 4,
                                py: 1,
                            }}
                            onClick={() => navigate(RoutesURL.FAQ)}
                        >
                            FAQ
                        </Typography>
                    </Stack>
                </Collapse>
            )}
        </nav>
    );
}
