import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { gapi } from 'gapi-script';
import {
    Badge,
    Box,
    Button,
    CircularProgress,
    Paper,
    Stack,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@mui/material';
import { Nav } from '../Nav';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import {
    DayCalendarSkeleton,
    PickersDay,
    PickersDayProps,
} from '@mui/x-date-pickers';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import BackyardDJ_small from '../../assets/BackyardDJ_small.png';
import MediumPackage_small from '../../assets/MediumPackage_small.png';
import TrussLights_small from '../../assets/TrussLights_small.png';
import LineArray_small from '../../assets/LineArray_small.png';
import { Footer } from '../Footer';
import { TextMaskCustom } from '../Contact/Contact';
import { postBooking } from '../../api/functions/postBooking';
import { RoutesURL } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';

const initialDate = new Date();
const initialValue = dayjs(initialDate);

const steps = ['Select Event Date', 'Select A Package', 'Confirm Appointment'];

function ServerDay(
    props: PickersDayProps<Dayjs> & {
        highlightedDays?: number[];
        blackoutDays?: number[];
    }
) {
    const {
        highlightedDays = [],
        blackoutDays = [],
        day,
        outsideCurrentMonth,
        ...other
    } = props;

    const isSelected =
        !props.outsideCurrentMonth &&
        highlightedDays.indexOf(props.day.date()) >= 0;

    const isSuperSelected =
        !props.outsideCurrentMonth &&
        blackoutDays.indexOf(props.day.date()) >= 0;

    return (
        <Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={
                isSuperSelected ? (
                    <CancelRoundedIcon
                        sx={{ color: 'red', fontSize: '12px' }}
                    />
                ) : isSelected ? (
                    <WarningAmberRoundedIcon
                        sx={{ color: '#FF00FC', fontSize: '12px' }}
                    />
                ) : undefined
            }
        >
            <PickersDay
                {...other}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
            />
        </Badge>
    );
}

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const Availability = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const initialPackage = urlParams.get('package') ?? '';

    const requestAbortController = React.useRef<AbortController | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showWarning, setShowWarning] = useState(false);
    const [selectedPackage, setSelectedPackage] =
        useState<string>(initialPackage);
    const [calValue, setCalValue] = React.useState<Dayjs | null>(null);
    const [highlightedDays, setHighlightedDays] = React.useState<number[]>([]);
    const [blackoutDays, setBlackoutDays] = React.useState<number[]>([]);
    const [currStep, setCurrStep] = useState<number>(0);
    const [isMobileView, setIsMobileView] = useState<boolean>(
        window.innerWidth <= 600
    );
    const [isTabletView, setIsTabletView] = useState<boolean>(
        window.innerWidth <= 1000
    );

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [messageSending, setMessageSending] = useState<number>(0);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 600);
            setIsTabletView(window.innerWidth <= 1000);
        };

        window.addEventListener('resize', handleResize);

        function start() {
            gapi.client
                .init({
                    apiKey: 'AIzaSyBLiIWRUNgLgvFVjPSqiiWrD8EUBCTz2sM',
                    discoveryDocs: [
                        'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
                    ],
                })
                .then(() => {
                    checkAvailability(initialValue);
                })
                .catch((error: any) => {
                    console.error('Error initializing GAPI client:', error);
                });
        }
        gapi.load('client:auth2', start);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const checkAvailability = (date: Dayjs) => {
        let asDateObj = date.toDate();
        let monthBegin = new Date(
            asDateObj.getFullYear(),
            asDateObj.getMonth()
        );
        let monthEnd = new Date(
            asDateObj.getFullYear(),
            asDateObj.getMonth() + 1
        );
        if (monthEnd < initialDate) {
            setIsLoading(false);
            return;
        }
        const request = {
            timeMin:
                monthBegin < initialDate
                    ? initialDate.toISOString()
                    : monthBegin.toISOString(),
            timeMax: monthEnd.toISOString(),
            items: [
                { id: 'kmdhg2tkhk0nq2qq2mg8d32brc@group.calendar.google.com' },
                {
                    id: '122d9e03435b3d91e7e26ad5fe90bb2a300b0f9a563bed1a65855abd3f46f395@group.calendar.google.com',
                },
            ],
            timeZone: 'America/Chicago',
        };

        gapi.client.calendar.freebusy
            .query({
                resource: request,
            })
            .then((response: any) => {
                let busyDates = response.result.calendars[
                    'kmdhg2tkhk0nq2qq2mg8d32brc@group.calendar.google.com'
                ].busy.map((events: any) => {
                    return [
                        new Date(events.start),
                        new Date(new Date(events.end).getTime() - 1000),
                    ];
                });
                let blackDates = response.result.calendars[
                    '122d9e03435b3d91e7e26ad5fe90bb2a300b0f9a563bed1a65855abd3f46f395@group.calendar.google.com'
                ].busy.map((events: any) => {
                    return [
                        new Date(events.start),
                        new Date(new Date(events.end).getTime() - 1000),
                    ];
                });
                setHighlightedDays(
                    busyDates
                        .map((busyTime: any) =>
                            Array.from(
                                {
                                    length:
                                        busyTime[1].getDate() -
                                        busyTime[0].getDate() +
                                        1,
                                },
                                (_, i) => i + busyTime[0].getDate()
                            )
                        )
                        .flat()
                );
                setBlackoutDays(
                    blackDates
                        .map((busyTime: any) =>
                            Array.from(
                                {
                                    length:
                                        busyTime[1].getDate() -
                                        busyTime[0].getDate() +
                                        1,
                                },
                                (_, i) => i + busyTime[0].getDate()
                            )
                        )
                        .flat()
                );
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error('Error checking freebusy:', error);
            });
    };

    const handleMonthChange = (date: Dayjs) => {
        if (requestAbortController.current) {
            // make sure that you are aborting useless requests
            // because it is possible to switch between months pretty quickly
            requestAbortController.current.abort();
        }

        setIsLoading(true);
        setHighlightedDays([]);
        checkAvailability(date);
    };

    const navigate = useNavigate();

    return (
        <Stack
            direction="column"
            sx={{ flexGrow: 1, height: 'fill-available' }}
        >
            <Nav />
            <Stack sx={{ backgroundColor: '#FCF9EF' }}>
                <Paper
                    elevation={3}
                    sx={{
                        backgroundColor: '#FFF',
                        width: isMobileView ? '100%' : 'inherit',
                        mx: isMobileView ? 'inherit' : 'auto',
                        my: isMobileView ? 'inherit' : 6,
                        mb: 6,
                        pt: 4,
                        pb: 2,
                        px: isMobileView ? 'inherit' : 6,
                    }}
                >
                    <Stepper activeStep={currStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step
                                key={label}
                                sx={{
                                    '& .MuiSvgIcon-root.Mui-completed': {
                                        color: '#D4AF37',
                                    },
                                    '& .MuiSvgIcon-root.Mui-active': {
                                        color: '#D4AF37',
                                    },
                                }}
                            >
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Paper>
                <Stack>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: isMobileView ? '42px' : '48px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            lineHeight: '1.2',
                            maxWidth: isMobileView ? 'inherit' : '90%',
                            textAlign: 'center',
                            color: '#161205',
                            mx: isMobileView ? 2 : 'auto',
                        }}
                    >
                        {currStep === 0
                            ? 'Select a date for your special day'
                            : currStep === 1
                              ? 'Select a starting package'
                              : currStep === 2
                                ? 'Enter your contact information'
                                : 'Appointment set!'}
                    </Typography>
                    <Stack gap={2} sx={{ my: 4 }}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: isMobileView ? '16px' : '18px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                lineHeight: '1.2',
                                color: '#2D2A1E',
                                maxWidth: isMobileView ? 'inherit' : '70%',
                                textAlign: 'center',
                                mx: isMobileView ? 2 : 'auto',
                            }}
                        >
                            {currStep === 0
                                ? 'We prioritize your time. Lets make sure that we are available to make your day all the more special before we proceed.'
                                : currStep === 1
                                  ? 'We have a variety of packages to choose from. Select the one that best fits your needs. We have additional products, such as uplights and gobo monograms, to further enhance your night. These products will be discussed during your consultation.'
                                  : currStep === 2
                                    ? 'Please provide us with any additional details that can help up better understand your needs and make your day as spectacular as possible!'
                                    : "We're almost there! One of our team members will reach out to you shortly. We typically call within 24 hours. If you have any questions, feel free to reach out to us at 224-612-0738 or info@RhythmAndRomanceDJs.com."}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: isMobileView ? '16px' : '18px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                lineHeight: '1.2',
                                color: '#2D2A1E',
                                maxWidth: isMobileView ? 'inherit' : '100%',
                                textAlign: 'center',
                                mx: isMobileView ? 2 : 'auto',
                            }}
                        >
                            {currStep === 0
                                ? 'Dates marked with a red X are unavailable.'
                                : currStep === 1
                                  ? 'Packages can be altered to better fit your needs and budget.'
                                  : currStep === 2
                                    ? ''
                                    : 'We are excited to be a part of your special day!'}
                        </Typography>
                    </Stack>
                </Stack>
                {currStep === 0 ? (
                    <Paper
                        elevation={3}
                        sx={{ backgroundColor: '#FFF', mx: 'auto' }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                loading={isLoading}
                                value={calValue}
                                onChange={(newCalValue) => {
                                    setCalValue(newCalValue);
                                    setShowWarning(
                                        newCalValue &&
                                            highlightedDays.includes(
                                                newCalValue.date()
                                            )
                                    );
                                }}
                                onMonthChange={handleMonthChange}
                                onYearChange={handleMonthChange}
                                renderLoading={() => <DayCalendarSkeleton />}
                                slots={{
                                    day: ServerDay,
                                }}
                                slotProps={{
                                    day: {
                                        highlightedDays,
                                        blackoutDays,
                                    } as any,
                                }}
                                disablePast
                                shouldDisableDate={(day) =>
                                    blackoutDays.includes(day.date()) ||
                                    day.isSame(initialValue, 'day')
                                }
                                sx={{
                                    '& .Mui-selected': {
                                        backgroundColor: '#D4AF37 !important',
                                    },
                                    '& .Mui-selected:focus': {
                                        backgroundColor: '#846b1c !important',
                                    },
                                    '& .Mui-selected:hover': {
                                        backgroundColor: '#846b1c !important',
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Paper>
                ) : currStep === 1 ? (
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        flexGrow={1}
                        gap={isMobileView ? 2 : 6}
                        sx={{
                            width: isMobileView ? '100%' : '85%',
                            mt: isMobileView ? 3 : 'inherit',
                            mx: 'auto',
                        }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor: '#FFF',
                                maxWidth: '900px',
                                cursor: 'pointer',
                                mx: isMobileView
                                    ? 2
                                    : isTabletView
                                      ? 8
                                      : 'inherit',
                                px: 0,
                            }}
                            onClick={() => {
                                setSelectedPackage('TheBasics');
                            }}
                        >
                            <Stack
                                direction={isTabletView ? 'column' : 'row'}
                                justifyContent="center"
                                flexGrow={1}
                                gap={isMobileView ? 0 : 0}
                                sx={{
                                    mt: 'inherit',
                                    backgroundColor:
                                        selectedPackage.toLocaleLowerCase() ===
                                        'TheBasics'.toLocaleLowerCase()
                                            ? '#D4AF37'
                                            : '#FFF',
                                    mx: isTabletView ? 0 : 'inherit',
                                }}
                            >
                                <Paper
                                    elevation={0}
                                    sx={{
                                        backgroundImage: `url(${BackyardDJ_small})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center center',
                                        borderRadius: '0',
                                        minHeight: '250px',
                                        minWidth: '250px',
                                        maxWidth: isTabletView
                                            ? 'inherit'
                                            : '350px',
                                        flexGrow: 1,
                                    }}
                                />
                                <Stack
                                    direction="column"
                                    gap={isMobileView ? 1 : 1}
                                    sx={{
                                        flexGrow: 1,
                                        mb: 2,
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        gap={isTabletView ? 1 : 5}
                                        justifyContent="space-between"
                                        sx={{
                                            pt: 4,
                                            px: 4,
                                            pb: 1,
                                            backgroundColor:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'TheBasics'.toLocaleLowerCase()
                                                    ? '#161205'
                                                    : 'inherit',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '38px',
                                                lineHeight: '52px',
                                                fontWeight: 600,
                                                fontFamily: 'Heebo, sans-serif',
                                                color:
                                                    selectedPackage.toLocaleLowerCase() ===
                                                    'TheBasics'.toLocaleLowerCase()
                                                        ? '#FFF'
                                                        : '#161205',
                                            }}
                                        >
                                            The Basics
                                        </Typography>
                                        <Stack
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            gap={1}
                                            sx={{ mt: -2 }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    lineHeight: '16px',
                                                    fontWeight: 600,
                                                    fontFamily:
                                                        'Heebo, sans-serif',
                                                    color:
                                                        selectedPackage.toLocaleLowerCase() ===
                                                        'TheBasics'.toLocaleLowerCase()
                                                            ? '#FCF9EF'
                                                            : '#846b1c',
                                                    mb: -1,
                                                }}
                                            >
                                                Starting at
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '38px',
                                                    lineHeight: '52px',
                                                    fontWeight: 600,
                                                    fontFamily:
                                                        'Heebo, sans-serif',
                                                    color:
                                                        selectedPackage.toLocaleLowerCase() ===
                                                        'TheBasics'.toLocaleLowerCase()
                                                            ? '#FFF'
                                                            : '#161205',
                                                }}
                                            >
                                                $599
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            fontWeight:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'TheBasics'.toLocaleLowerCase()
                                                    ? 500
                                                    : 400,
                                            fontFamily: 'Heebo, sans-serif',
                                            color:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'TheBasics'.toLocaleLowerCase()
                                                    ? '#FFF'
                                                    : '#2D2A1E',
                                            px: 4,
                                        }}
                                    >
                                        Includes 2 speakers and a single bar of
                                        backlighting to give the dance floor
                                        some color. Ideal for backyard parties
                                        and small gatherings. This package is
                                        perfect for those on a budget.
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor: '#FFF',
                                maxWidth: '900px',
                                cursor: 'pointer',
                                mx: isMobileView
                                    ? 2
                                    : isTabletView
                                      ? 8
                                      : 'inherit',
                                px: 0,
                            }}
                            onClick={() => {
                                setSelectedPackage('PartyStarter');
                            }}
                        >
                            <Stack
                                direction={isTabletView ? 'column' : 'row'}
                                justifyContent="center"
                                flexGrow={1}
                                gap={isTabletView ? 0 : 0}
                                sx={{
                                    mt: 'inherit',
                                    backgroundColor:
                                        selectedPackage.toLocaleLowerCase() ===
                                        'PartyStarter'.toLocaleLowerCase()
                                            ? '#D4AF37'
                                            : '#FFF',
                                }}
                            >
                                <Paper
                                    elevation={0}
                                    sx={{
                                        backgroundImage: `url(${MediumPackage_small})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center center',
                                        borderRadius: '0',
                                        minHeight: '250px',
                                        minWidth: '250px',
                                        maxWidth: isTabletView
                                            ? 'inherit'
                                            : '350px',
                                        flexGrow: 1,
                                    }}
                                />
                                <Stack
                                    direction="column"
                                    gap={1}
                                    sx={{
                                        flexGrow: 1,
                                        mb: 2,
                                        mx: 0,
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        gap={isTabletView ? 1 : 5}
                                        justifyContent="space-between"
                                        sx={{
                                            pt: 4,
                                            px: 4,
                                            pb: 1,
                                            backgroundColor:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'PartyStarter'.toLocaleLowerCase()
                                                    ? '#161205'
                                                    : 'inherit',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '38px',
                                                lineHeight: '52px',
                                                fontWeight: 600,
                                                fontFamily: 'Heebo, sans-serif',
                                                color:
                                                    selectedPackage.toLocaleLowerCase() ===
                                                    'PartyStarter'.toLocaleLowerCase()
                                                        ? '#FFF'
                                                        : '#161205',
                                            }}
                                        >
                                            Party Starter
                                        </Typography>
                                        <Stack
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            gap={1}
                                            sx={{ mt: -2 }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    lineHeight: '16px',
                                                    fontWeight: 600,
                                                    fontFamily:
                                                        'Heebo, sans-serif',
                                                    color:
                                                        selectedPackage.toLocaleLowerCase() ===
                                                        'PartyStarter'.toLocaleLowerCase()
                                                            ? '#FCF9EF'
                                                            : '#846b1c',
                                                    mb: -1,
                                                }}
                                            >
                                                Starting at
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '38px',
                                                    lineHeight: '52px',
                                                    fontWeight: 600,
                                                    fontFamily:
                                                        'Heebo, sans-serif',
                                                    color:
                                                        selectedPackage.toLocaleLowerCase() ===
                                                        'PartyStarter'.toLocaleLowerCase()
                                                            ? '#FFF'
                                                            : '#161205',
                                                }}
                                            >
                                                $1,199
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            fontWeight:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'PartyStarter'.toLocaleLowerCase()
                                                    ? 500
                                                    : 400,
                                            fontFamily: 'Heebo, sans-serif',
                                            color:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'PartyStarter'.toLocaleLowerCase()
                                                    ? '#FFF'
                                                    : '#2D2A1E',
                                            px: 4,
                                        }}
                                    >
                                        Includes 4 speakers, 1 subwoofer, 2 bars
                                        of backlighting, and 2 moving lights
                                        sitting on covered trussing that is
                                        illuminated to your wedding colors.
                                        Ideal for a large room or small wedding
                                        hall.
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor: '#FFF',
                                maxWidth: '900px',
                                cursor: 'pointer',
                                mx: isMobileView
                                    ? 2
                                    : isTabletView
                                      ? 8
                                      : 'inherit',
                                px: 0,
                            }}
                            onClick={() => {
                                setSelectedPackage('Premium');
                            }}
                        >
                            <Stack
                                direction={isTabletView ? 'column' : 'row'}
                                justifyContent="center"
                                flexGrow={1}
                                gap={0}
                                sx={{
                                    mt: 'inherit',
                                    backgroundColor:
                                        selectedPackage.toLocaleLowerCase() ===
                                        'Premium'.toLocaleLowerCase()
                                            ? '#D4AF37'
                                            : '#FFF',
                                }}
                            >
                                <Paper
                                    elevation={0}
                                    sx={{
                                        backgroundImage: `url(${TrussLights_small})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center center',
                                        borderRadius: '0',
                                        minHeight: '250px',
                                        minWidth: '250px',
                                        maxWidth: isTabletView
                                            ? 'inherit'
                                            : '350px',
                                        flexGrow: 1,
                                    }}
                                />
                                <Stack
                                    direction="column"
                                    gap={1}
                                    sx={{
                                        flexGrow: 1,
                                        mb: 2,
                                        mx: 0,
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        gap={isTabletView ? 1 : 5}
                                        justifyContent="space-between"
                                        sx={{
                                            pt: 4,
                                            px: 4,
                                            pb: 1,
                                            backgroundColor:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'Premium'.toLocaleLowerCase()
                                                    ? '#161205'
                                                    : 'inherit',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '38px',
                                                lineHeight: '52px',
                                                fontWeight: 600,
                                                fontFamily: 'Heebo, sans-serif',
                                                color:
                                                    selectedPackage.toLocaleLowerCase() ===
                                                    'Premium'.toLocaleLowerCase()
                                                        ? '#FFF'
                                                        : '#161205',
                                            }}
                                        >
                                            Premium
                                        </Typography>
                                        <Stack
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            gap={1}
                                            sx={{ mt: -2 }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    lineHeight: '16px',
                                                    fontWeight: 600,
                                                    fontFamily:
                                                        'Heebo, sans-serif',
                                                    color:
                                                        selectedPackage.toLocaleLowerCase() ===
                                                        'Premium'.toLocaleLowerCase()
                                                            ? '#FCF9EF'
                                                            : '#846b1c',
                                                    mb: -1,
                                                }}
                                            >
                                                Starting at
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '38px',
                                                    lineHeight: '52px',
                                                    fontWeight: 600,
                                                    fontFamily:
                                                        'Heebo, sans-serif',
                                                    color:
                                                        selectedPackage.toLocaleLowerCase() ===
                                                        'Premium'.toLocaleLowerCase()
                                                            ? '#FFF'
                                                            : '#161205',
                                                }}
                                            >
                                                $1,599
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            fontWeight:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'Premium'.toLocaleLowerCase()
                                                    ? 500
                                                    : 400,
                                            fontFamily: 'Heebo, sans-serif',
                                            color:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'Premium'.toLocaleLowerCase()
                                                    ? '#FFF'
                                                    : '#2D2A1E',
                                            px: 4,
                                        }}
                                    >
                                        Includes 2 stacks of speakers comprising
                                        of a compact array and subwoofers, 2
                                        auxilary speakers, 4 moving lights
                                        sitting on covered trussing, and 8 wash
                                        lights affixed to the trussing. Ideal
                                        for small and large wedding halls.
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor: '#FFF',
                                maxWidth: '900px',
                                cursor: 'pointer',
                                mx: isMobileView
                                    ? 2
                                    : isTabletView
                                      ? 8
                                      : 'inherit',
                                px: 0,
                            }}
                            onClick={() => {
                                setSelectedPackage('Festival');
                            }}
                        >
                            <Stack
                                direction={isTabletView ? 'column' : 'row'}
                                justifyContent="center"
                                flexGrow={1}
                                gap={0}
                                sx={{
                                    mt: 'inherit',
                                    backgroundColor:
                                        selectedPackage.toLocaleLowerCase() ===
                                        'Festival'.toLocaleLowerCase()
                                            ? '#D4AF37'
                                            : '#FFF',
                                }}
                            >
                                <Paper
                                    elevation={0}
                                    sx={{
                                        backgroundImage: `url(${LineArray_small})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center center',
                                        borderRadius: '0',
                                        minHeight: '250px',
                                        minWidth: '250px',
                                        maxWidth: isTabletView
                                            ? 'inherit'
                                            : '350px',
                                        flexGrow: 1,
                                    }}
                                />
                                <Stack
                                    direction="column"
                                    gap={1}
                                    sx={{
                                        flexGrow: 1,
                                        mb: 2,
                                        mx: 0,
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        gap={isTabletView ? 1 : 5}
                                        justifyContent="space-between"
                                        sx={{
                                            pt: 4,
                                            px: 4,
                                            pb: 1,
                                            backgroundColor:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'Festival'.toLocaleLowerCase()
                                                    ? '#161205'
                                                    : 'inherit',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '38px',
                                                lineHeight: '52px',
                                                fontWeight: 600,
                                                fontFamily: 'Heebo, sans-serif',
                                                color:
                                                    selectedPackage.toLocaleLowerCase() ===
                                                    'Festival'.toLocaleLowerCase()
                                                        ? '#FFF'
                                                        : '#161205',
                                            }}
                                        >
                                            Festival
                                        </Typography>
                                        <Stack
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            gap={1}
                                            sx={{ mt: -2 }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    lineHeight: '16px',
                                                    fontWeight: 600,
                                                    fontFamily:
                                                        'Heebo, sans-serif',
                                                    color:
                                                        selectedPackage.toLocaleLowerCase() ===
                                                        'Festival'.toLocaleLowerCase()
                                                            ? '#FCF9EF'
                                                            : '#846b1c',
                                                    mb: -1,
                                                }}
                                            >
                                                Starting at
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '38px',
                                                    lineHeight: '52px',
                                                    fontWeight: 600,
                                                    fontFamily:
                                                        'Heebo, sans-serif',
                                                    color:
                                                        selectedPackage.toLocaleLowerCase() ===
                                                        'Festival'.toLocaleLowerCase()
                                                            ? '#FFF'
                                                            : '#161205',
                                                }}
                                            >
                                                $3,799
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            fontWeight:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'Festival'.toLocaleLowerCase()
                                                    ? 500
                                                    : 400,
                                            fontFamily: 'Heebo, sans-serif',
                                            color:
                                                selectedPackage.toLocaleLowerCase() ===
                                                'Festival'.toLocaleLowerCase()
                                                    ? '#FFF'
                                                    : '#2D2A1E',
                                            px: 4,
                                        }}
                                    >
                                        Includes everything you need for a
                                        spectacular concert; most notably a full
                                        60kW line array {`(`}the same ones we
                                        use as festivals like Lollapolloza
                                        {')'} and up to 36 dance floor lights.
                                        Ideal for real music lovers and party
                                        people that want to blow the roof off!
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Stack>
                ) : currStep === 2 ? (
                    <Paper
                        elevation={3}
                        sx={{
                            backgroundColor: '#FFF',
                            width: isMobileView ? '100%' : 'inherit',
                            mx: isMobileView ? 'inherit' : 'auto',
                            my: 0,
                            py: isMobileView ? 2 : 6,
                            px: isMobileView ? 2 : 6,
                        }}
                    >
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            flexGrow={1}
                            gap={2}
                            sx={{
                                width: isMobileView ? '100%' : '100%',
                                mx: 'auto',
                            }}
                        >
                            <Stack
                                direction="row"
                                flexGrow={1}
                                sx={{
                                    width: 'fill-available',
                                    color: '#FFF',
                                    gap: 2,
                                }}
                            >
                                <TextField
                                    id="Name"
                                    label="First Name"
                                    variant="outlined"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setName(event.target.value);
                                    }}
                                />
                                <TextField
                                    id="Email"
                                    label="E-Mail"
                                    variant="outlined"
                                    fullWidth
                                    error={
                                        !(email === '' || validateEmail(email))
                                    }
                                    helperText={
                                        email === '' || validateEmail(email)
                                            ? ''
                                            : 'Please enter a valid email address'
                                    }
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                            </Stack>
                            <TextField
                                id="PhoneNumber"
                                type="tel"
                                label="Phone Number (optional)"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    inputComponent: TextMaskCustom as any,
                                }}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setPhone(event.target.value);
                                }}
                            />
                            <TextField
                                id="message"
                                label="Additional Details (optional)"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={isMobileView ? 10 : 6}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setMessage(event.target.value);
                                }}
                            />
                        </Stack>
                    </Paper>
                ) : null}
                {currStep === 0 && showWarning && (
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            fontFamily: 'Heebo, sans-serif',
                            lineHeight: '1.2',
                            color: '#2D2A1E',
                            maxWidth: isMobileView ? 'inherit' : '100%',
                            textAlign: 'center',
                            mx: 'auto',
                            fontStyle: 'italic',
                            width: '320px',
                            my: 1,
                        }}
                    >
                        <WarningAmberRoundedIcon sx={{ color: '#FF00FC' }} />
                        One or more of our DJs are booked on this date, we
                        recommend calling ASAP to ensure that we have exactly
                        what you need to make your night a success.
                    </Typography>
                )}
                {currStep === 1 &&
                    selectedPackage.toLocaleLowerCase() ===
                        'Festival'.toLocaleLowerCase() && (
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                lineHeight: '1.2',
                                color: '#2D2A1E',
                                maxWidth: isMobileView ? 'inherit' : '100%',
                                textAlign: 'center',
                                mx: 'auto',
                                fontStyle: 'italic',
                                width: '320px',
                                my: 1,
                            }}
                        >
                            <WarningAmberRoundedIcon
                                sx={{ color: '#FF00FC' }}
                            />
                            This package requires a large number of staff and
                            our full inventory. Please book as far in advance as
                            possible to ensure that we can provide the necessary
                            equipment and staff.
                        </Typography>
                    )}
                {currStep === 0 ? (
                    <Button
                        variant="contained"
                        disabled={!calValue}
                        sx={{
                            my: isMobileView ? 5 : showWarning ? 5 : 10,
                            mb: isMobileView ? 5 : 10,
                            mx: 'auto',
                            textTransform: 'none',
                            backgroundColor: '#D4AF37',
                            ':hover': { backgroundColor: '#846b1c' },
                        }}
                        onClick={() => {
                            setCurrStep(1);
                        }}
                    >
                        {initialPackage
                            ? 'Confirm Package'
                            : 'Select A Package'}
                    </Button>
                ) : currStep === 1 ? (
                    <Button
                        variant="contained"
                        disabled={!selectedPackage}
                        sx={{
                            my: isMobileView
                                ? 5
                                : selectedPackage.toLocaleLowerCase() ===
                                    'Festival'.toLocaleLowerCase()
                                  ? 5
                                  : 10,
                            mb: isMobileView ? 5 : 10,
                            mx: 'auto',
                            textTransform: 'none',
                            backgroundColor: '#D4AF37',
                            ':hover': { backgroundColor: '#846b1c' },
                        }}
                        onClick={() => {
                            setCurrStep(2);
                        }}
                    >
                        Confirm Appointment
                    </Button>
                ) : currStep === 2 ? (
                    <Button
                        variant="contained"
                        disabled={
                            !!(
                                email === '' ||
                                !validateEmail(email) ||
                                name === '' ||
                                (phone.length !== 14 &&
                                    phone.length !== 0 &&
                                    phone !== '(')
                            )
                        }
                        sx={{
                            my: isMobileView ? 5 : 10,
                            mt: isMobileView ? 5 : 8,
                            mx: 'auto',
                            textTransform: 'none',
                            backgroundColor: '#D4AF37',
                            ':hover': { backgroundColor: '#846b1c' },
                        }}
                        onClick={() => {
                            setMessageSending(1);
                            postBooking(
                                name,
                                email,
                                phone,
                                message,
                                calValue?.format('MM/DD/YYYY'),
                                selectedPackage
                            ).then(() => {
                                setTimeout(() => {
                                    setCurrStep(3);
                                    navigate(RoutesURL.Confirmation);
                                }, 2000);
                            });
                        }}
                    >
                        {messageSending === 0 ? (
                            'Submit'
                        ) : (
                            <CircularProgress
                                size="24px"
                                color="inherit"
                                sx={{ mx: 1.5 }}
                            />
                        )}
                    </Button>
                ) : null}
            </Stack>
            <Box
                sx={{ backgroundColor: '#FCF9EF', height: 'fill-available' }}
            />
            <Footer />
        </Stack>
    );
};
