import {
    Box,
    Button,
    CircularProgress,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Nav } from '../Nav';
import { Footer } from '../Footer';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { IMaskInput } from 'react-imask';
import { postInquery } from '../../api/functions/postInquery';
import { validateEmail } from '../Availability/Availability';

const { palette } = createTheme();
const theme = createTheme({
    palette: {
        mode: 'dark',
        transparent: palette.augmentColor({
            color: {
                main: 'rgba(255, 255, 255, 0)',
            },
        }),
        white: palette.augmentColor({ color: { main: '#FFFFFF' } }),
    },
});

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="(#00) 000-0000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) =>
                    onChange({ target: { name: props.name, value } })
                }
                overwrite
            />
        );
    }
);

export function Contact() {
    const [isMobileView, setIsMobileView] = useState<boolean>(
        window.innerWidth <= 600
    );
    const [isTabletView, setIsTabletView] = useState<boolean>(
        window.innerWidth <= 1000
    );

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [messageSending, setMessageSending] = useState<number>(0);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 600);
            setIsTabletView(window.innerWidth <= 1000);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Stack direction="column" sx={{ flexGrow: 1, minHeight: '100%' }}>
            <Nav />
            <Stack
                direction={isMobileView ? 'column' : 'row'}
                alignItems="center"
                justifyContent="center"
                flexGrow={1}
                gap={isMobileView ? 2 : 6}
                sx={{
                    backgroundColor: '#FCF9EF',
                    py: 5,
                    px: isMobileView ? 3 : 10,
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: isMobileView ? '42px' : '48px',
                        lineHeight: isMobileView ? '56px' : '64px',
                        fontWeight: 600,
                        fontFamily: 'Heebo, sans-serif',
                        color: '#161205',
                        // textAlign: isMobileView ? 'center' : 'inherit',
                    }}
                >
                    We want to hear from you!
                </Typography>
            </Stack>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Stack
                    direction={isTabletView ? 'column' : 'row'}
                    alignItems="center"
                    justifyContent="center"
                    flexGrow={1}
                    gap={isMobileView ? 8 : 8}
                    sx={{
                        backgroundColor: '#161205',
                        py: isMobileView ? 6 : 12,
                        px: isMobileView ? 'inherit' : isTabletView ? 10 : 20,
                    }}
                >
                    <Stack
                        direction="column"
                        justifyContent="center"
                        flexGrow={1}
                        gap={isMobileView ? 2 : 4}
                        flexBasis={'65%'}
                        alignSelf="flex-start"
                        sx={{
                            mx: isMobileView ? 3 : 'auto',
                        }}
                    >
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: isMobileView ? '42px' : '48px',
                                lineHeight: isMobileView ? '56px' : '64px',
                                fontWeight: 500,
                                fontFamily: 'Heebo, sans-serif',
                                color: '#FFF',
                                // textAlign: isMobileView ? 'center' : 'inherit',
                            }}
                        >
                            Contact Rhythm & Romance
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: isMobileView ? '16px' : '16px',
                                lineHeight: isMobileView ? '24px' : '24px',
                                fontWeight: 400,
                                fontFamily: 'Heebo, sans-serif',
                                color: '#FFFFFFCC',
                                // textAlign: isMobileView ? 'center' : 'inherit',
                            }}
                        >
                            Need a DJ for your wedding, party or event? Contact
                            our premier DJ service below. Our team of
                            experienced, professional DJs will make your wedding
                            truly memorable. We typically respond within 24
                            hours.
                        </Typography>
                        <Stack
                            direction="column"
                            sx={{
                                color: '#FFF',
                                justifyContent: 'space-between',
                                gap: 2,
                            }}
                        >
                            <Stack
                                direction="row"
                                sx={{
                                    color: '#FFF',
                                    justifyContent: 'space-between',
                                    gap: 2,
                                }}
                            >
                                <TextField
                                    id="Name"
                                    label="First Name"
                                    variant="outlined"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setName(event.target.value);
                                    }}
                                />
                                <TextField
                                    id="Email"
                                    label="E-Mail"
                                    variant="outlined"
                                    fullWidth
                                    error={
                                        !(email === '' || validateEmail(email))
                                    }
                                    helperText={
                                        email === '' || validateEmail(email)
                                            ? ''
                                            : 'Please enter a valid email address'
                                    }
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                            </Stack>
                            <TextField
                                id="PhoneNumber"
                                type="tel"
                                label="Phone Number (optional)"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    inputComponent: TextMaskCustom as any,
                                }}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setPhone(event.target.value);
                                }}
                            />
                            <TextField
                                id="message"
                                label="Your Message"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={isMobileView ? 10 : 6}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setMessage(event.target.value);
                                }}
                            />
                            <Button
                                variant="contained"
                                color="white"
                                disabled={
                                    !!(
                                        email === '' ||
                                        !validateEmail(email) ||
                                        name === '' ||
                                        (phone.length !== 14 &&
                                            phone.length !== 0 &&
                                            phone !== '(')
                                    )
                                }
                                onClick={() => {
                                    setMessageSending(1);
                                    postInquery(
                                        name,
                                        email,
                                        phone,
                                        message
                                    ).then(() => {
                                        setTimeout(() => {
                                            setMessageSending(2);
                                            setTimeout(() => {
                                                setMessageSending(3);
                                            }, 10000);
                                        }, 2000);
                                    });
                                }}
                            >
                                {messageSending === 0 ? (
                                    'Send Message'
                                ) : messageSending === 1 ? (
                                    <CircularProgress
                                        size="24px"
                                        color="inherit"
                                    />
                                ) : messageSending === 2 ? (
                                    'Sent!'
                                ) : (
                                    'Send Another Message'
                                )}
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        alignSelf="flex-start"
                        gap={isMobileView ? 4 : 6}
                        flexBasis={'35%'}
                        flexShrink={1}
                        sx={{
                            mx: isMobileView ? 3 : 'inherit',
                        }}
                    >
                        <Stack sx={{ alignSelf: 'flex-start' }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontSize: isMobileView ? '30px' : '38px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#FFF',
                                    alignSelf: 'flex-start',
                                    // textAlign: isMobileView ? 'center' : 'inherit',
                                }}
                            >
                                Email
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#FFFFFFCC',
                                    alignSelf: 'flex-start',
                                }}
                            >
                                For general inquiries or questions, email us at
                                info@RhythmAndRomanceDJs.com. We typically
                                respond within 24 hours.
                            </Typography>
                        </Stack>
                        <Stack sx={{ alignSelf: 'flex-start' }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontSize: isMobileView ? '30px' : '38px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#FFF',
                                    alignSelf: 'flex-start',
                                    // textAlign: isMobileView ? 'center' : 'inherit',
                                }}
                            >
                                Phone
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#FFFFFFCC',
                                    alignSelf: 'flex-start',
                                }}
                            >
                                Call 224-612-0738 to speak with a DJ services
                                specialist.
                            </Typography>
                        </Stack>
                        <Stack sx={{ alignSelf: 'flex-start' }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontSize: isMobileView ? '30px' : '38px',
                                    fontWeight: 500,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#FFF',
                                    alignSelf: 'flex-start',
                                    // textAlign: isMobileView ? 'center' : 'inherit',
                                }}
                            >
                                Form
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#FFFFFFCC',
                                    alignSelf: 'flex-start',
                                }}
                            >
                                Fill out the contact form with your event
                                details and any special requests.
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </ThemeProvider>
            <Box
                sx={{
                    backgroundColor: '#161205',
                    pb: isMobileView ? 6 : 10,
                    px: isMobileView ? 3 : 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontSize: isMobileView ? '28px' : '34px',
                        fontWeight: 500,
                        fontFamily: 'Heebo, sans-serif',
                        color: '#FFF',
                        alignSelf: 'center',
                        // textAlign: isMobileView ? 'center' : 'inherit',
                    }}
                >
                    We hope to hear from you soon!
                </Typography>
            </Box>
            <Footer />
        </Stack>
    );
}
