import { Button, Divider, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Nav } from '../Nav';

import HeadphonesRoundedIcon from '@mui/icons-material/HeadphonesRounded';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';
import MicExternalOnRoundedIcon from '@mui/icons-material/MicExternalOnRounded';
import SpeakerRoundedIcon from '@mui/icons-material/SpeakerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Footer } from '../Footer';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { RoutesURL } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import BackyardDJ_small from '../../assets/BackyardDJ_small.png';
import MediumPackage_small from '../../assets/MediumPackage_small.png';
import TrussLights_small from '../../assets/TrussLights_small.png';
import LineArray_small from '../../assets/LineArray_small.png';

export function Services() {
    const [isMobileView, setIsMobileView] = useState<boolean>(
        window.innerWidth <= 600
    );
    const [isTabletView, setIsTabletView] = useState<boolean>(
        window.innerWidth <= 1000
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 600);
            setIsTabletView(window.innerWidth <= 1000);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navigate = useNavigate();

    return (
        <Stack direction="column" sx={{ flexGrow: 1 }}>
            <Nav />
            <Stack
                direction={'column'}
                alignItems="center"
                justifyContent="center"
                flexGrow={1}
                gap={isMobileView ? 2 : 6}
                sx={{
                    backgroundColor: '#161205',
                    py: 6,
                    px: isMobileView ? 3 : 12,
                }}
            >
                <Stack
                    direction={'column'}
                    alignItems="flex-start"
                    justifyContent="center"
                    flexGrow={1}
                    gap={2}
                    sx={{ pb: isTabletView ? 2 : 'inherit' }}
                >
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: isMobileView ? '42px' : '48px',
                            lineHeight: isMobileView ? '56px' : '64px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#FFF',
                            // textAlign: isMobileView ? 'center' : 'inherit',
                        }}
                    >
                        DJ Services for Your Wedding
                    </Typography>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '16px',
                            lineHeight: '24px',
                            fontWeight: 400,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#FFFFFFCC',
                            width: isTabletView ? 'inherit' : '70%',
                            // textAlign: isMobileView ? 'center' : 'inherit',
                        }}
                    >
                        With over 500 weddings under our belt, Rhythm & Romance
                        offers a full range of DJ services to make your big day
                        truly special. We offer consultation before the wedding,
                        a custom music playlist for the reception, an engaging
                        and fun emcee experience, dynamic dance floor lighting
                        effects, and wireless microphones for the couple's vows.
                    </Typography>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="center"
                    flexGrow={1}
                    gap={3}
                >
                    <Stack
                        direction={isTabletView ? 'column' : 'row'}
                        justifyContent="center"
                        flexGrow={1}
                        gap={3}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                flexBasis: '50%',
                            }}
                        >
                            <Stack
                                direction="column"
                                gap={2}
                                useFlexGap
                                sx={{ flexGrow: 1 }}
                            >
                                <HeadphonesRoundedIcon
                                    sx={{ color: '#D4AF37', fontSize: '40px' }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '30px',
                                        lineHeight: '40px',
                                        fontWeight: 600,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#161205',
                                        mb: isTabletView ? 'inherit' : 1,
                                    }}
                                >
                                    Music
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        fontWeight: 400,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#2D2A1E',
                                    }}
                                >
                                    We'll consult with you ahead of time to
                                    craft the perfect music playlist, including
                                    all your must-play songs and styles.
                                </Typography>
                            </Stack>
                        </Paper>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                flexBasis: '25%',
                            }}
                        >
                            <Stack
                                direction="column"
                                gap={2}
                                useFlexGap
                                sx={{ flexGrow: 1 }}
                            >
                                <MicRoundedIcon
                                    sx={{ color: '#D4AF37', fontSize: '40px' }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '30px',
                                        lineHeight: '40px',
                                        fontWeight: 600,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#161205',
                                        mb: isTabletView ? 'inherit' : 1,
                                    }}
                                >
                                    Emcee
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        fontWeight: 400,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#2D2A1E',
                                    }}
                                >
                                    Our experienced emcees will keep your
                                    reception flowing smoothly and get your
                                    guests excited on the dance floor.
                                </Typography>
                            </Stack>
                        </Paper>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                flexBasis: '25%',
                            }}
                        >
                            <Stack
                                direction="column"
                                gap={2}
                                useFlexGap
                                sx={{ flexGrow: 1 }}
                            >
                                <LightbulbRoundedIcon
                                    sx={{ color: '#D4AF37', fontSize: '40px' }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '30px',
                                        lineHeight: '40px',
                                        fontWeight: 600,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#161205',
                                        mb: isTabletView ? 'inherit' : 1,
                                    }}
                                >
                                    Lighting
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        fontWeight: 400,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#2D2A1E',
                                    }}
                                >
                                    Up lighting, dance floor lights and more to
                                    create the perfect atmosphere for your
                                    celebration.
                                </Typography>
                            </Stack>
                        </Paper>
                    </Stack>
                    <Stack
                        direction={isTabletView ? 'column' : 'row'}
                        justifyContent="center"
                        flexGrow={1}
                        gap={3}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                flexBasis: '25%',
                            }}
                        >
                            <Stack
                                direction="column"
                                gap={2}
                                useFlexGap
                                sx={{ flexGrow: 1 }}
                            >
                                <MicExternalOnRoundedIcon
                                    sx={{ color: '#D4AF37', fontSize: '40px' }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '30px',
                                        lineHeight: '40px',
                                        fontWeight: 600,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#161205',
                                        mb: isTabletView ? 'inherit' : 1,
                                    }}
                                >
                                    Vows
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        fontWeight: 400,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#2D2A1E',
                                    }}
                                >
                                    Secondary audio system with wireless mics so
                                    your heartfelt vows are clearly heard by all
                                    guests.
                                </Typography>
                            </Stack>
                        </Paper>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                flexBasis: '50%',
                            }}
                        >
                            <Stack
                                direction="column"
                                gap={2}
                                useFlexGap
                                sx={{ flexGrow: 1 }}
                            >
                                <SpeakerRoundedIcon
                                    sx={{ color: '#D4AF37', fontSize: '40px' }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '30px',
                                        lineHeight: '40px',
                                        fontWeight: 600,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#161205',
                                        mb: isTabletView ? 'inherit' : 1,
                                    }}
                                >
                                    Live Support
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        fontWeight: 400,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#2D2A1E',
                                    }}
                                >
                                    If its a band you want, we will make it
                                    happen. We have an arsenal of equipment to
                                    support any artist{'('}s{')'} that makes an
                                    appearence at your wedding.
                                </Typography>
                            </Stack>
                        </Paper>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                flexBasis: '25%',
                            }}
                        >
                            <Stack
                                direction="column"
                                gap={2}
                                useFlexGap
                                sx={{ flexGrow: 1 }}
                            >
                                <PersonRoundedIcon
                                    sx={{ color: '#D4AF37', fontSize: '40px' }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '30px',
                                        lineHeight: '40px',
                                        fontWeight: 600,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#161205',
                                        mb: isTabletView ? 'inherit' : 1,
                                    }}
                                >
                                    Staff
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        fontWeight: 400,
                                        fontFamily: 'Heebo, sans-serif',
                                        color: '#2D2A1E',
                                    }}
                                >
                                    Friendly, experienced DJs to ensure your
                                    wedding goes off without a hitch.
                                </Typography>
                            </Stack>
                        </Paper>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                direction={'column'}
                alignItems="center"
                justifyContent="center"
                flexGrow={1}
                gap={isTabletView ? 2 : 6}
                sx={{
                    backgroundColor: '#FCF9EF',
                    py: isTabletView ? 6 : 12,
                    px: isTabletView ? 3 : 12,
                }}
            >
                <Stack
                    direction={isTabletView ? 'column' : 'row'}
                    justifyContent="center"
                    alignItems="center"
                    flexGrow={1}
                    gap={isTabletView ? 3 : 6}
                    sx={{ width: isMobileView ? 'inherit' : '90%' }}
                >
                    <Typography
                        sx={{
                            fontSize: isTabletView ? '42px' : '48px',
                            lineHeight: isTabletView ? '56px' : '64px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#2D2A1E',
                            flexBasis: isTabletView ? 'inherit' : '40%',
                            textAlign: isTabletView ? 'center' : 'inherit',
                        }}
                    >
                        DJ packages for your perfect party
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            lineHeight: '24px',
                            fontWeight: 400,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#2D2A1E',
                            flexBasis: isTabletView ? 'inherit' : '60%',
                            textAlign: isTabletView ? 'center' : 'inherit',
                            pr: isTabletView ? 'inherit' : 8,
                        }}
                    >
                        Each of our packages fit a different wedding celebration
                        vision and price range, while ensuring top quality
                        entertainment and service. Every package is built from
                        tour-grade audio equipment; we will never compromise on
                        audio quality. Each package also includes emcee services
                        and the DJ's direct line for any questions or concerns.
                    </Typography>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    flexGrow={1}
                    gap={isTabletView ? 2 : 6}
                    sx={{
                        width: isMobileView
                            ? '100%'
                            : isTabletView
                              ? '80%'
                              : '85%',
                        mt: isMobileView ? 3 : 'inherit',
                    }}
                >
                    <Stack
                        direction={isTabletView ? 'column' : 'row'}
                        justifyContent="center"
                        flexGrow={1}
                        gap={isTabletView ? 0 : 4}
                        sx={{
                            mt: isMobileView ? 2 : isTabletView ? 4 : 'inherit',
                            width: '100%',
                        }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundImage: `url(${BackyardDJ_small})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                borderRadius: '15px',
                                minHeight: '250px',
                                flexBasis: '25%',
                            }}
                        />
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            gap={isTabletView ? 1 : 2}
                            sx={{ flexBasis: '50%', flexGrow: 1, mt: 2 }}
                        >
                            <Stack direction="row" gap={0}>
                                <AttachMoneyRoundedIcon
                                    sx={{ fontSize: '24px', color: '#D4AF37' }}
                                />
                            </Stack>
                            <Typography
                                sx={{
                                    fontSize: '38px',
                                    lineHeight: '52px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                The Basics
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#2D2A1E',
                                }}
                            >
                                Includes 2 speakers and a single bar of
                                backlighting to give the dance floor some color.
                                Ideal for backyard parties and small gatherings.
                                This package is perfect for those on a budget.
                            </Typography>
                        </Stack>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            gap={1}
                            sx={{
                                flexBasis: '25%',
                                flexGrow: 1,
                                mt: isTabletView ? 3 : 5,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#846b1c',
                                    mb: -1,
                                }}
                            >
                                Starting at
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '38px',
                                    lineHeight: '52px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                $599
                            </Typography>
                            <Button
                                variant="text"
                                endIcon={<ArrowForwardRoundedIcon />}
                                sx={{
                                    textTransform: 'none',
                                    color: '#D4AF37',
                                    ':hover': {
                                        backgroundColor:
                                            'rgba(83, 69, 22, 0.1)',
                                    },
                                    fontSize: '16px',
                                }}
                                onClick={() =>
                                    navigate(
                                        RoutesURL.Availability +
                                            '?package=TheBasics'
                                    )
                                }
                            >
                                Book Now
                            </Button>
                        </Stack>
                    </Stack>
                    {isTabletView && (
                        <Divider
                            variant="middle"
                            flexItem
                            sx={{
                                borderBottomWidth: '3px',
                                borderRadius: '2px 2px 2px 2px',
                            }}
                        />
                    )}
                    <Stack
                        direction={isTabletView ? 'column' : 'row'}
                        justifyContent="center"
                        flexGrow={1}
                        gap={isTabletView ? 0 : 4}
                        sx={{ mt: isTabletView ? 2 : 'inherit', width: '100%' }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundImage: `url(${MediumPackage_small})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                borderRadius: '15px',
                                minHeight: '250px',
                                flexBasis: '25%',
                            }}
                        />
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            gap={isTabletView ? 1 : 2}
                            sx={{ flexBasis: '50%', flexGrow: 1, mt: 2 }}
                        >
                            <Stack direction="row" gap={0}>
                                <AttachMoneyRoundedIcon
                                    sx={{ fontSize: '24px', color: '#D4AF37' }}
                                />
                                <AttachMoneyRoundedIcon
                                    sx={{ fontSize: '24px', color: '#D4AF37' }}
                                />
                            </Stack>
                            <Typography
                                sx={{
                                    fontSize: '38px',
                                    lineHeight: '52px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                Party Starter
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#2D2A1E',
                                }}
                            >
                                Includes 4 speakers, 1 subwoofer, 2 bars of
                                backlighting, and 2 moving lights sitting on
                                covered trussing that is illuminated to your
                                wedding colors. Ideal for a large room or small
                                wedding hall.
                            </Typography>
                        </Stack>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            gap={1}
                            sx={{
                                flexBasis: '25%',
                                flexGrow: 1,
                                mt: isTabletView ? 3 : 5,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#846b1c',
                                    mb: -1,
                                }}
                            >
                                Starting at
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '38px',
                                    lineHeight: '52px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                $1,199
                            </Typography>
                            <Button
                                variant="text"
                                endIcon={<ArrowForwardRoundedIcon />}
                                sx={{
                                    textTransform: 'none',
                                    color: '#D4AF37',
                                    ':hover': {
                                        backgroundColor:
                                            'rgba(83, 69, 22, 0.1)',
                                    },
                                    fontSize: '16px',
                                }}
                                onClick={() =>
                                    navigate(
                                        RoutesURL.Availability +
                                            '?package=PartyStarter'
                                    )
                                }
                            >
                                Book Now
                            </Button>
                        </Stack>
                    </Stack>
                    {isTabletView && (
                        <Divider
                            variant="middle"
                            flexItem
                            sx={{
                                borderBottomWidth: '3px',
                                borderRadius: '2px 2px 2px 2px',
                            }}
                        />
                    )}
                    <Stack
                        direction={isTabletView ? 'column' : 'row'}
                        justifyContent="center"
                        flexGrow={1}
                        gap={isTabletView ? 0 : 4}
                        sx={{ mt: isTabletView ? 2 : 'inherit', width: '100%' }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundImage: `url(${TrussLights_small})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                borderRadius: '15px',
                                minHeight: '250px',
                                flexBasis: '25%',
                            }}
                        />
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            gap={isTabletView ? 1 : 2}
                            sx={{ flexBasis: '50%', flexGrow: 1, mt: 2 }}
                        >
                            <Stack direction="row" gap={0}>
                                <AttachMoneyRoundedIcon
                                    sx={{ fontSize: '24px', color: '#D4AF37' }}
                                />
                                <AttachMoneyRoundedIcon
                                    sx={{ fontSize: '24px', color: '#D4AF37' }}
                                />
                                <AttachMoneyRoundedIcon
                                    sx={{ fontSize: '24px', color: '#D4AF37' }}
                                />
                            </Stack>
                            <Typography
                                sx={{
                                    fontSize: '38px',
                                    lineHeight: '52px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                Premium
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#2D2A1E',
                                }}
                            >
                                Includes 2 stacks of speakers comprising of a
                                compact array and subwoofers, 2 auxilary
                                speakers, 4 moving lights sitting on covered
                                trussing, and 8 wash lights affixed to the
                                trussing. Ideal for small and large wedding
                                halls.
                            </Typography>
                        </Stack>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            gap={1}
                            sx={{
                                flexBasis: '25%',
                                flexGrow: 1,
                                mt: isTabletView ? 3 : 5,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#846b1c',
                                    mb: -1,
                                }}
                            >
                                Starting at
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '38px',
                                    lineHeight: '52px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                $1,599
                            </Typography>
                            <Button
                                variant="text"
                                endIcon={<ArrowForwardRoundedIcon />}
                                sx={{
                                    textTransform: 'none',
                                    color: '#D4AF37',
                                    ':hover': {
                                        backgroundColor:
                                            'rgba(83, 69, 22, 0.1)',
                                    },
                                    fontSize: '16px',
                                }}
                                onClick={() =>
                                    navigate(
                                        RoutesURL.Availability +
                                            '?package=premium'
                                    )
                                }
                            >
                                Book Now
                            </Button>
                        </Stack>
                    </Stack>
                    {isTabletView && (
                        <Divider
                            variant="middle"
                            flexItem
                            sx={{
                                borderBottomWidth: '3px',
                                borderRadius: '2px 2px 2px 2px',
                            }}
                        />
                    )}
                    <Stack
                        direction={isTabletView ? 'column' : 'row'}
                        justifyContent="center"
                        flexGrow={1}
                        gap={isTabletView ? 0 : 4}
                        sx={{ mt: isTabletView ? 2 : 'inherit', width: '100%' }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundImage: `url(${LineArray_small})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                borderRadius: '15px',
                                minHeight: '250px',
                                flexBasis: '25%',
                            }}
                        />
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            gap={isTabletView ? 1 : 2}
                            sx={{ flexBasis: '50%', flexGrow: 1, mt: 2 }}
                        >
                            <Stack direction="row" gap={0}>
                                <AttachMoneyRoundedIcon
                                    sx={{ fontSize: '24px', color: '#D4AF37' }}
                                />
                                <AttachMoneyRoundedIcon
                                    sx={{ fontSize: '24px', color: '#D4AF37' }}
                                />
                                <AttachMoneyRoundedIcon
                                    sx={{ fontSize: '24px', color: '#D4AF37' }}
                                />
                                <AttachMoneyRoundedIcon
                                    sx={{ fontSize: '24px', color: '#D4AF37' }}
                                />
                                <AttachMoneyRoundedIcon
                                    sx={{ fontSize: '24px', color: '#D4AF37' }}
                                />
                            </Stack>
                            <Typography
                                sx={{
                                    fontSize: '38px',
                                    lineHeight: '52px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                Festival
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontWeight: 400,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#2D2A1E',
                                }}
                            >
                                Includes everything you need for a spectacular
                                concert; most notably a full 60kW line array{' '}
                                {`(`}the same ones we use as festivals like
                                Lollapolloza
                                {')'} and up to 36 dance floor lights. Ideal for
                                real music lovers and party people that want to
                                blow the roof off!
                            </Typography>
                        </Stack>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            gap={1}
                            sx={{
                                flexBasis: '25%',
                                flexGrow: 1,
                                mt: isTabletView ? 3 : 5,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#846b1c',
                                    mb: -1,
                                }}
                            >
                                Starting at
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '38px',
                                    lineHeight: '52px',
                                    fontWeight: 600,
                                    fontFamily: 'Heebo, sans-serif',
                                    color: '#161205',
                                }}
                            >
                                $3,799
                            </Typography>
                            <Button
                                variant="text"
                                endIcon={<LocalPhoneRoundedIcon />}
                                sx={{
                                    textTransform: 'none',
                                    color: '#D4AF37',
                                    ':hover': {
                                        backgroundColor:
                                            'rgba(83, 69, 22, 0.1)',
                                    },
                                    fontSize: '16px',
                                }}
                                onClick={() => navigate(RoutesURL.Contact)}
                            >
                                Contact Us
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                direction={isTabletView ? 'column' : 'row'}
                alignItems="center"
                justifyContent="center"
                flexGrow={1}
                gap={isMobileView ? 2 : 6}
                sx={{
                    backgroundColor: '#FFF',
                    py: isMobileView ? 4 : 10,
                    px: isMobileView ? 3 : 12,
                }}
            >
                <Stack direction="column" flexBasis="70%" gap={2}>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            lineHeight: '24px',
                            fontWeight: 400,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#2D2A1E',
                        }}
                    >
                        Request a customized quote for your event.
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: isMobileView ? '24px' : '48px',
                            lineHeight: isMobileView ? '32px' : '64px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#161205',
                        }}
                    >
                        Connect With Our DJ Service
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            lineHeight: '24px',
                            fontWeight: 400,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#2D2A1E',
                        }}
                    >
                        We understand that every wedding is different and we
                        have so much more to offer. Feel free to call
                        224-612-0738 or email info@RhythmAndRomanceDJs.com to
                        get a quote tailored to your wedding or event DJ and
                        emcee needs.
                    </Typography>
                </Stack>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: 'none',
                        backgroundColor: '#D4AF37',
                        fontSize: '16px',
                        ':hover': { backgroundColor: '#846b1c' },
                        alignSelf: isMobileView ? 'flex-start' : 'inherit',
                    }}
                    onClick={() => navigate(RoutesURL.Contact)}
                >
                    Get Quote
                </Button>
            </Stack>
            <Footer />
        </Stack>
    );
}
