export async function postBooking(
    name: any,
    email: any,
    phone: any,
    message: any,
    calValue: any,
    selectedPackage: any
) {
    const url =
        'https://us-central1-rhythm-and-romance.cloudfunctions.net/function-2';

    const requestData: any = {
        name: name,
        email: email,
        phone: phone,
        message: message,
        date: calValue,
        package: selectedPackage,
    };

    const body = JSON.stringify(requestData);

    return fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-goog-api-key': 'AIzaSyBLiIWRUNgLgvFVjPSqiiWrD8EUBCTz2sM',
        },
        body: body,
    })
        .then((response) => {
            return response.text();
        })
        .then((data) => {
            return data ? JSON.parse(data) : {};
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
}
