import { PaletteColorOptions, ThemeProvider, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface Palette {
        positive: PaletteColorOptions;
        negative: PaletteColorOptions;
        nuetral: PaletteColorOptions;
        white: PaletteColorOptions;
    }

    interface PaletteOptions {
        positive?: PaletteColorOptions;
        negative?: PaletteColorOptions;
        nuetral?: PaletteColorOptions;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        positive: true;
        negative: true;
        nuetral: true;
        white: true;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsColorOverrides {
        positive: true;
        negative: true;
        nuetral: true;
    }
}

declare module '@mui/material/TextField' {
    interface TextFieldPropsColorOverrides {
        white: true;
    }
}

const { palette } = createTheme();
export const theme = createTheme({
    palette: {
        transparent: palette.augmentColor({
            color: {
                main: 'rgba(255, 255, 255, 0)',
            },
        }),
        white: palette.augmentColor({ color: { main: '#FFFFFF' } }),
    },
});

export default function Theme({ children }: { children: React.ReactNode }) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
