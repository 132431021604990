import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { RoutesURL } from './constants/routes';
import {
    DJs,
    Home,
    FAQ,
    Services,
    Contact,
    Availability,
    Confirmation,
} from './components';
import { ScrollToTop } from './overrides/ScrollToTop';

export class AppRouter extends Component {
    render() {
        return (
            <React.Fragment>
                <Box width="100%" height="100%">
                    <ScrollToTop>
                        <Routes>
                            <Route index element={<Home />} />
                            <Route
                                path={RoutesURL.Services}
                                element={<Services />}
                            />
                            <Route path={RoutesURL.DJs} element={<DJs />} />
                            <Route path={RoutesURL.FAQ} element={<FAQ />} />
                            <Route
                                path={RoutesURL.Contact}
                                element={<Contact />}
                            />
                            <Route
                                path={RoutesURL.Availability}
                                element={<Availability />}
                            />
                            <Route
                                path={RoutesURL.Confirmation}
                                element={<Confirmation />}
                            />
                            <Route
                                path={RoutesURL.notFound}
                                element={
                                    <Navigate replace to={RoutesURL.index} />
                                }
                            />
                        </Routes>
                    </ScrollToTop>
                </Box>
            </React.Fragment>
        );
    }
}
