import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Nav } from '../Nav';
import { useEffect, useState } from 'react';
import { Footer } from '../Footer';
import DJBride from '../../assets/SmallDJBride.png';

export function FAQ() {
    const [isMobileView, setIsMobileView] = useState<boolean>(
        window.innerWidth <= 600
    );
    const [isTabletView, setIsTabletView] = useState<boolean>(
        window.innerWidth <= 1000
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 600);
            setIsTabletView(window.innerWidth <= 1000);
        };

        const urlParams = new URLSearchParams(window.location.search);
        const initialQuestion = urlParams.get('question') ?? '';

        if (initialQuestion) {
            const element = document.getElementById(initialQuestion);
            //you can do it by jquery. no matter
            element?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Stack direction="column" sx={{ flexGrow: 1 }}>
            <Nav />
            <Stack
                direction={isMobileView ? 'column' : 'row'}
                gap={4}
                sx={{
                    backgroundColor: '#FCF9EF',
                    flexGrow: 1,
                    py: isMobileView ? 4 : 6,
                    pb: isMobileView ? 0 : 6,
                    justifyContent: 'center',
                }}
            >
                {!isMobileView && (
                    <Paper
                        elevation={8}
                        sx={{
                            backgroundImage: `url(${DJBride})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            flexBasis: isMobileView
                                ? 'inherit'
                                : isTabletView
                                  ? '45%'
                                  : '35%',
                            borderRadius: '15px',
                            minHeight: '300px',
                        }}
                    />
                )}
                <Stack
                    direction="column"
                    sx={{
                        flexBasis: isMobileView ? 'inherit' : '35%',
                        my: 2,
                        mb: isMobileView ? 4 : 6,
                    }}
                >
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: '48px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#161205',
                            lineHeight: '1.2',
                            mb: isMobileView ? 0 : 4,
                            textAlign: isMobileView ? 'center' : 'left',
                            mx: isMobileView ? 3 : 'inherit',
                        }}
                    >
                        Dont let this be you!
                    </Typography>
                    {isMobileView && (
                        <Paper
                            elevation={8}
                            sx={{
                                backgroundImage: `url(${DJBride})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                borderRadius: 0,
                                height: '225px',
                                my: 3,
                            }}
                        />
                    )}
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#161205',
                            lineHeight: '1.5',
                            textAlign: isMobileView ? 'center' : 'left',
                            mx: isMobileView ? 3 : 'inherit',
                        }}
                    >
                        Maked informed decisions to make your perfect day as
                        stress-free as possible. If your question isn't answered
                        below, please feel free to reach out to us at
                        info@RhythmAndRomanceDJs.com or call us at 224-612-0738.
                    </Typography>
                </Stack>
            </Stack>
            <Box
                sx={{
                    backgroundColor: '#D4AF37',
                    width: '100%',
                }}
            >
                <Stack
                    direction="column"
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px',
                        mx: 'auto',
                        width: isMobileView ? '100%' : '70%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobileView ? '32px' : '36px',
                            lineHeight: isMobileView ? '48px' : '54px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#FFF',
                            mt: 4,
                        }}
                    >
                        Our Inventory
                    </Typography>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            What kind of speakers and microphones can you
                            supply?
                        </AccordionSummary>
                        <AccordionDetails>
                            Every speaker we use is professional-grade and
                            tour-ready JBL speakers. More specifically, we use
                            JBL PRX, SRX, and Vertec. We also use Shure
                            microphones, which are the industry standard for all
                            things live sound. Every package includes a wireless
                            handheld microphone for speeches and the MC.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            What is so special about "professional" and
                            "tour-grade" gear?
                        </AccordionSummary>
                        <AccordionDetails>
                            "Professional equipment" is a term that gets thrown
                            around a lot in the DJ industry and has lost a lot
                            of meaning. That is why we partnered with RLC Sound
                            to provide top-of-class wooden JBL cabinets that are
                            paired with Crown amplifiers, which are miles beyond
                            the average DJ's "professional" QSC rig. Our
                            equipment is used by touring musicians and sound
                            engineers alike. This means that our equipment is
                            reliable, sounds great, and is built to last. We
                            take pride in our gear and invest in the best
                            equipment to ensure that your event sounds amazing.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Do you bring any lights or effects for the dance
                            floor?
                        </AccordionSummary>
                        <AccordionDetails>
                            You betcha! We have a variety of lights and effects
                            that we can bring to your event. We have everything
                            from simple uplights to moving head lights. We can
                            also provide fog machines and lasers [yes, we are
                            certified]. We plan on expanding our effects to
                            sparklers and confetti cannons as soon as we aquire
                            the proper certifications. We can work with you to
                            create a lighting package that fits your event
                            perfectly. We are here to make your event special
                            and that includes the lighting.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            How can you elevate the look of the venue?
                        </AccordionSummary>
                        <AccordionDetails>
                            One of the easiest ways we can elevate the look of
                            your venue is the lighting. Specifically, we carry a
                            variety of uplights that can be placed around the
                            room to create a warm and inviting atmosphere. We
                            can also provide a monogram projector that can
                            project your names onto the wall, ceiling, or dance
                            floor. Let us know that you are interested in these
                            packages ASAP as monograms take time to design and
                            create.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Can you also provide microphones and speakers for
                            the ceremony?
                        </AccordionSummary>
                        <AccordionDetails>
                            Of course! We can provide a wireless microphones for
                            the officiant, as well as a speaker for the ceremony
                            music. We can also provide additional microphones
                            for couple, if needed. The DJ will also be available
                            to play enterance and exit music for the ceremony.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Can you provide a stage for my event?
                        </AccordionSummary>
                        <AccordionDetails>
                            At this time, we only provide a 8'x4' riser which
                            would be appropiate for a DJ booth. We are currently
                            working on building a larger stage for bands, other
                            live performers, or speeches. We will update this
                            question when a larger stage becomes available.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Can you provide a dance floor for my event?
                        </AccordionSummary>
                        <AccordionDetails>
                            Unfortunately not. We do not provide dance floors,
                            but we plan to purchase two in the near future. We
                            will update this question when can provide a dance
                            floor.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        sx={{ width: '100%' }}
                        defaultExpanded={
                            (new URLSearchParams(window.location.search).get(
                                'question'
                            ) ?? '') === 'SeeGearQuestion'
                        }
                        id="SeeGearQuestion"
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Is there an extensive list of equipment available?
                        </AccordionSummary>
                        <AccordionDetails>
                            Unfortunately, not yet. We are currently working
                            with our partners to create a full list of equipment
                            that we can provide. Please reference the above
                            questions for general equipment inqueries. We will
                            update this question when we have a full list of
                            equipment available.
                        </AccordionDetails>
                    </Accordion>
                    <Typography
                        sx={{
                            fontSize: isMobileView ? '32px' : '36px',
                            lineHeight: isMobileView ? '48px' : '54px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#FFF',
                            mt: 4,
                        }}
                    >
                        Venues and Location
                    </Typography>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            What areas do you service?
                        </AccordionSummary>
                        <AccordionDetails>
                            We travel everywhere! We are based in Chicago, IL
                            and will travel anywhere in the Chicago and
                            Milwaukee area's with no additional charge. We also
                            travel to other states and countries. We have done
                            events in California, New York, Washington D.C., and
                            even Canada. We love to travel and are always up for
                            an adventure. If you have a destination wedding or
                            event, we would love to be a part of it!
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Do you only service weddings?
                        </AccordionSummary>
                        <AccordionDetails>
                            We have done everything from a 15 person wedding to
                            a 5000 person corporate event. We have the
                            experience and equipment to handle any event. We
                            love to party and are always up for a good time. We
                            are currently working on building our more generic
                            event DJ brand, but feel free to reference the
                            pricing on this site for the time being. We do not
                            believe in the "wedding tax" and insist that pricing
                            will stay consistant between event types and brands.
                            If you have an event that needs music, we would love
                            to be a part of it!
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            How large/small of an wedding will you service?
                        </AccordionSummary>
                        <AccordionDetails>
                            We do it all! Our partnered company, RLC Sound, has
                            provided equipment for the side stages of
                            Lollapalooza and is rated for over 8000 people.
                            Because of our partnership, we can provide the
                            highest quality equipment to parties of 15 or 5000.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Our outdoor venue doesn't have outlets! Can you
                            help?
                        </AccordionSummary>
                        <AccordionDetails>
                            Thats where our generator comes in! We can provide a
                            generator to power our equipment. Our generators are
                            also quiet enough not to become a distraction to
                            guests. This is perfect for outdoor venues that
                            don't have power outlets.
                        </AccordionDetails>
                    </Accordion>
                    <Typography
                        sx={{
                            fontSize: isMobileView ? '32px' : '36px',
                            lineHeight: isMobileView ? '48px' : '54px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#FFF',
                            mt: 4,
                        }}
                    >
                        Pricing
                    </Typography>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Can you explain your pricing?
                        </AccordionSummary>
                        <AccordionDetails>
                            We pride outselves on our transparent pricing. Each
                            of members of staff and DJs have a decade of
                            technical/entertainment experience and need to make
                            a living too. Each DJ is paid a flat rate of $500
                            and every other member of staff is paid a adjustable
                            rate depending on their role and duration they are
                            on site. From there, we provide all equipment at
                            industry standard rates. For small and medium sized
                            rigs utilizing a PRX system, we price match
                            ourselves with Guitar Center's QSC rentals which
                            ensures you are getting higher quality gear at the
                            same cost of doing it yourselves.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Wow, you really pay out your DJs that much?
                        </AccordionSummary>
                        <AccordionDetails>
                            Yes! They deserve it. Not only are they the face of
                            our company, but they are also the ones who spend
                            hours both behind the scenes and on the stage to
                            make your big day special. We want to attract the
                            best DJs in the industry and we know that paying
                            them well is the best way to do that. We also
                            believe in transparency and want to make sure that
                            you know where your money is going. We are proud to
                            pay our DJs a fair wage and we know that you will be
                            happy with the results.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            What if I don't want gear? Can you provide a DJ
                            only?
                        </AccordionSummary>
                        <AccordionDetails>
                            We are happy to provide a DJ only, but we do not
                            recommend it. As stated in the question{' '}
                            <i>"Can you explain your pricing?,"</i> the flat
                            rate for one of our DJs is $500. We don't recommend
                            this option because our rigs are constructed by
                            sound engineers and lighting designers. When you
                            decide to only hire the DJ, you are risking
                            incompatibilities between equipment that we may not
                            have the equipment or cables on hand to fix.
                        </AccordionDetails>
                    </Accordion>
                    <Typography
                        sx={{
                            fontSize: isMobileView ? '32px' : '36px',
                            lineHeight: isMobileView ? '48px' : '54px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#FFF',
                            mt: 4,
                        }}
                    >
                        DJs and Music
                    </Typography>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Do we get to speak with the DJ prior to the big day?
                        </AccordionSummary>
                        <AccordionDetails>
                            Absolutely, your DJ will reach out to you to help
                            plan your night and you will have their direct line.
                            We are here to make your wedding special and we
                            believe that our availability is important to make
                            your special day a reality. On top of planning your
                            music, we can also work with you to create a
                            timeline for the night and make sure that everything
                            goes smoothly. Do note that our DJs are often busy
                            at night, and can often be reached during 9-5
                            business hours.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            You have a few DJs, do we get to choose who we want?
                        </AccordionSummary>
                        <AccordionDetails>
                            As long as they are currently available, you can
                            choose your DJ. Although our DJs have specialties in
                            different genres and styles of music, each of our
                            DJs are well versed in many genres. If your DJ is
                            not available, we are confident that any of our DJs
                            will be able to make your event special.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Do you have any bilingual DJs?
                        </AccordionSummary>
                        <AccordionDetails>
                            Currently, our only bilingual DJ is DJ Ric. He is
                            fluent in Spanish and English and can provide a
                            bilingual experience for your event.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            I am thinking of having a live band perform instead
                            of a DJ. How do I decide?
                        </AccordionSummary>
                        <AccordionDetails>
                            Why not both! We have worked with many live bands
                            and can provide a seamless transition between the
                            two. We can provide a sound engineer and all
                            necessary equipment to make sure that the band
                            sounds great and that the DJ can take over when the
                            band is done. We can also provide a DJ to play music
                            during the band's breaks. We are here to make your
                            wedding special and that includes working with other
                            vendors to make sure that everything goes smoothly.
                            We also have a list of bands that we have worked
                            with in the past and can recommend for your event.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            What's this I hear about "Hybrid DJing"?
                        </AccordionSummary>
                        <AccordionDetails>
                            This is a fun project DJ Pat has been working on for
                            the past year to elevate his events. Hybrid DJing is
                            the combination of DJing and live music. DJ Pat has
                            been working on a setup that allows artists to tap
                            in their instruments or vocals and play along with
                            the music that he is playing. This is a great way to
                            add a personal touch to your wedding and make it
                            truly unique. DJ Pat is the only DJ in the company
                            that offers this service, but we are working on
                            expanding it to the other DJs. If you are
                            interested, just let us know and we can make it
                            happen!
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            What kind of music do you play?
                        </AccordionSummary>
                        <AccordionDetails>
                            That is a question meant for you! We play what you
                            want. Each DJ may have their specialties, but we are
                            all well versed in all genres of music. We will work
                            with you to create a playlist that fits your wedding
                            perfectly. We are here to make your big day special
                            and that includes the music.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        sx={{ width: '100%' }}
                        defaultExpanded={
                            (new URLSearchParams(window.location.search).get(
                                'question'
                            ) ?? '') === 'SeeMusicQuestion'
                        }
                        id="SeeMusicQuestion"
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Is there an sample library of music I can view?
                        </AccordionSummary>
                        <AccordionDetails>
                            Unfortunately, not yet. Each DJ has tens of
                            thousands of tracks and we are currently working on
                            compiling a list that we feel best represents our
                            collective music library. Feel free to reach out to
                            us in the meantime to discuss music selection. We
                            will update this question when we have a sample
                            library of music available.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            How do you handle song requests?
                        </AccordionSummary>
                        <AccordionDetails>
                            Unless instructed otherwise, we will take requests
                            from your guests as long as it matches the energy of
                            the playlist that we have curated together.
                            Otherwise, we will play anything that you tell us to
                            play. We will work with you to create a playlist
                            that fits your wedding perfectly. We will also work
                            with you to create a "Do Not Play" list to ensure
                            that your big day is exactly how you want it.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            What can you do to make my wedding fun and unique?
                        </AccordionSummary>
                        <AccordionDetails>
                            The best way to make your wedding fun and unique is
                            to incorporate crowd participation. A few examples
                            is the photograph game or anniversary game. We can
                            also provide special effects like a monogram
                            projector that can project your names onto the wall,
                            ceiling, or dance floor.
                        </AccordionDetails>
                    </Accordion>
                    <Typography
                        sx={{
                            fontSize: isMobileView ? '32px' : '36px',
                            lineHeight: isMobileView ? '48px' : '54px',
                            fontWeight: 600,
                            fontFamily: 'Heebo, sans-serif',
                            color: '#FFF',
                            mt: 4,
                        }}
                    >
                        Vendors
                    </Typography>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Will you be able to work with our videographer or
                            other vendors?
                        </AccordionSummary>
                        <AccordionDetails>
                            As soon as we are on site, we make three new best
                            friends: your photographer, videographer, and
                            coordinator. The only thing almost as important as
                            creating incredible memories is capturing them to
                            look back on. We are here to make your wedding
                            special and that includes working with other vendors
                            to make sure that everything goes smoothly. We are
                            happy to provide a list of vendors that we have
                            worked with in the past and can recommend for your
                            event.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Can you recommend any vendors (photographers /
                            videographers / musicians)?
                        </AccordionSummary>
                        <AccordionDetails>
                            We have worked with many vendors in the past and can
                            put you in touch with some of the best in the
                            industry. Please feel free to reach out to us with
                            the specific type of vendor you are looking for and
                            we can provide you with a list of vendors that we
                            can recommend for your event.
                        </AccordionDetails>
                    </Accordion>
                </Stack>
            </Box>
            <Footer />
        </Stack>
    );
}
